import React, { useState } from 'react';
import '../../asstes/css/sass/Sidebar.scss';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleLinkClick = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  };

  return (
    <>
      <button
        id="toggle-button"
        className={isCollapsed ? 'collapsed' : ''}
        onClick={handleCollapseToggle}
      >
        {isCollapsed ? (
          <span className="fa fa-arrow-left"> Show</span>
        ) : (
          <span className="fa fa-arrow-right"> Hide</span>
        )}
      </button>

      <div className={`s-soft ${isCollapsed ? 'so-collapse' : ''}`}>

      <div class="list-group">
        <Link class="list-group-item" to="/USA/upcomingevents"><i class="fa fa-calendar fa-fw" aria-hidden="true"></i>&nbsp; Event</Link>
        <Link class="list-group-item" to="/USA/ActivityCalendar"><i class="fa flaticon-calendar fa-fw" aria-hidden="true"></i>&nbsp; YK Calendar</Link>
        <Link class="list-group-item" to="/USA/wordsofwisdom"><i class="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; Words of Wisdom </Link> 
        
</div>
        {/* <ul className="sidebarmenu">
          <li>   
            <Link to=" /USA/OurEvent" className="s-item facebook" onClick={handleLinkClick}>
          Events
        </Link></li>

        <li>   
            <Link to="/USA/ActivityCalendar" className="s-item facebook" onClick={handleLinkClick}>
          YK Calendar
        </Link></li>
        

        <li>
        <Link to="/" className="s-item facebook" onClick={handleLinkClick}>
         Classes
        </Link>
        </li>


        </ul> */}
      
       
       
      </div>
    </>
  );
};

export default Sidebar;
