import ReactDOM from "react-dom/client";
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './asstes/css/sass/style.scss';
import './asstes/css/sass/responsive.scss';
import GoogleTag from "./Canada/Component/GoogleTag.jsx";
// import { hydrate, render } from "react-dom";

import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>

<GoogleTag/>

     <App />
</>



);


