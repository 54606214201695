import { setAlbums, setPhotos } from '../reducers/album';
import { fetchAlbums, fetchPhotos } from '../services/facebook';
// import { useDispatch} from 'react-redux';
// Replace 'your-page-id' and 'your-access-token' with your actual values
const PAGE_ID = 142517325615384;
const ACCESS_TOKEN = 'EAAEMFm5W7ggBO7y5wv7p8NYuEvvRasd1QBQj7GB1ZCZBpuyOAXrcZBnZCtkYZBUH3oAQf2lK3TiZBkdVpKodNT7OVsH5lVGmKYr8ftN53VjXHLmZCdoEW8FtYY2ORSOIMoa2xHsOvRjsszsZAjmNq2aw2fdnI6NQaKZAyQ4i5waXT1kQYHuQ1HZAoR3ZCt0';


export const getAlbums = () => async (dispatch) => {
  try {
   
    const albums = await fetchAlbums(PAGE_ID, ACCESS_TOKEN);
    dispatch(setAlbums(albums));
    console.log(albums);
  } catch (error) {
    // Handle error
  }
}






export const getAlbumPhotos = (albumId) => async (dispatch)  => {
  try {    
    //const dispatch = useDispatch();
    const photos = await fetchPhotos(albumId, ACCESS_TOKEN);
    dispatch(setPhotos(photos));
    console.log(photos);
  } catch (error) {
    console.error('Error fetching album photos:', error);
  }
};









