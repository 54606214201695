const GalleryCardData =[
  {
  id:1,
  imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r1.JPG",
  Link:"https://stssevastorage.blob.core.windows.net/retreat/r1.JPG",



  },


  {
    id:2,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r2.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r2.JPG",
  
},


{
    id:3,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r3.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r3.JPG",
  
},


{
    id:4,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r4.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r4.JPG",
  
},


{
    id:5,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r5.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r5.JPG",
  
},


{
    id:6,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r6.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r6.JPG",
  
},


{
    id:7,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r7.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r7.JPG",
  
},


{
    id:8,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r8.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r8.JPG",
  
},


{
    id:9,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r9.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r9.JPG",
  
},

{
    id:10,
    imgsrc:"https://stssevastorage.blob.core.windows.net/retreat/r10.JPG",
    Link:"https://stssevastorage.blob.core.windows.net/retreat/r10.JPG",
  
},

{
    id:11,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/1.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/1.jpg",
  
},

{
    id:12,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/2.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/2.jpg",
  
},



{
    id:13,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/3.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/3.jpg",
  
},

{
    id:14,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/4.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/4.jpg",
  
},

{
    id:15,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/5.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/5.jpg",
  
},


{
    id:16,
    imgsrc:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/6.jpg",
    Link:"https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/6.jpg",
  
},




]

export default GalleryCardData;