const EventCardData = [

    {

        id: 1,
        title: "Winter Family Retreat",
        links: "https://ykportal.yugalkunj.org/?ParamCountry=Canada",
        lablename: "Please click here for registration ",
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykportal/Canada/Winter%20Retreat.jpeg",
        imglinks: "https://stssevastorage.blob.core.windows.net/ykportal/Canada/Winter%20Retreat.jpeg"

        
        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },

    {

        id: 2,
        title: "Weekly Satsangs",
        links: "https://yugalkunj.org/images/satsang.jpg",
        lablename: "View Flyer ",
        imgsrc: "https://yugalkunj.org/images/satsang.jpg",
        imglinks: "https://yugalkunj.org/images/satsang.jpg"


        // Desc:"The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........ "



    },


    






]

export default EventCardData;