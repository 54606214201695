const WhatWeDo =(props) => {
   return(
  <>
                  <div className="service-block col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0ms">
                    <div className="inner-box">
                        <div className="icon">
                            <span className={props.imgicon}></span>
                        </div>
                        <h3><a href={props.Link}>{props.title}</a></h3>
                        <div className="text">
                        {props.Desc}

                        </div>
                        <div className="link-box"><a href={props.Link} className="theme-btn">{ props.Readtext}</a></div>
                    </div>
                </div> 
                {/* <!--Service Block--> */}

            
  
  </>

   )

}

export default WhatWeDo;