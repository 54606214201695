const GalleryCard = (props) => {
  return(
   <>
       {/* <!--Activity Block--> */}
       <div className="activity-block mix all retreat col-md-6 col-sm-12" style={{display: "inline-block"}}>
                <div className="inner-box">
                    <figure className="image">
                        <img src={props.imgsrc} alt=""/>
                        <a href={props.Link} className="lightbox-image overlay" data-fancybox="gallery-two"><span className="icon fa fa-search-plus"></span></a>
                    </figure>
                    {/* <!--  <div className="caption-box">
    <div className="cap-inner">
        <h3><a href="#">Holi At Yugal Kunj </a></h3>
        <div className="cat-info"><a href="#">Save Animals</a>,<a href="#">Charity</a></div>
    </div>
</div> --> */}
                </div>
            </div>
   </>

  )


}


export default GalleryCard;