const BlogsData =[
    {
    id:1,
    title: ' Canmore Retreat 2019',
    EventDate: 'Sep 21, 2019',

    imgsrc:"https://radhamadhavsociety.org/Content/Images/Blog/canmore-retreat-1a.jpg",
    Link:'/USA/BlogDetails',
    Desc: 'Devotees from various parts of Canada and US gathered for a retreat near Banff, Canada. Guided by Sushri Siddheshvari Devi Ji, this third annual retreat was held at Copperstone Resort at the base of Rocky Mountains. The natural beauty of the surroundings perfectly complimented....'
  
  
  
    },

    {
        id:2,
        title: ' Toronto Retreat 2019',
        EventDate: ' Aug 20, 2019',
    
        imgsrc:"https://radhamadhavsociety.org/Content/Images/Blog/toronto-retreat-2019-1.jpg",
        Link:'/USA/BlogDetails',
        Desc: 'Didi Ji recently conducted the annual family retreat in Toronto. Every year, this retreat is held over the first weekend in August. The retreat was held at the serene and lush green site of Regina Mundi. It was attended by around 150 participants of all ages...'
      
      
      
        },

        {
            id:3,
            title: 'Memorial Day Retreat',
            EventDate: ' Aug 20, 2019',
        
            imgsrc:"https://radhamadhavsociety.org/Content/Images/Blog/toronto-retreat-2019-1.jpg",
            Link:'/USA/BlogDetails',
            Desc: " Didi Ji guides several retreats all over the US and Canada during the year. There have been two that have already been held this year at 'Shyama Shyam Dham' in Milwaukee (March 2019) and 'Shyama Sadan' in Ohio (May 2019 during the Memorial Day weekend). Three others are being planned..."
          
          
          
            }
  
  
  
  
  
  
  ]
  
  export default BlogsData;