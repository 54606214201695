  import {Link } from 'react-router-dom';

 
const BlogsCard =(props)=> {
   return (
  <>
     <div className="cause-block col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                            <div className="image-box">
                                <figure className="image"><Link to={props.link}>
                                    <img src={props.imgsrc} alt="blogimg"/></Link>
                                    </figure>
                                
                            </div>
                            <div className="lower-content">
                                <h3><a href={props.link}>{props.title}</a></h3>
                                <div className="cause-title"> <i className="far fa-clock"></i> {props.EventDate}</div>
                                <div className="text">{props.Desc}</div>
                                
                            </div>
                            <div className="link-box"><a href={props.link} className="theme-btn btn-style-two"><span className="btn-title">Read More</span></a></div>
                        </div>
                    </div>

   
  
  </>


   )

}

export default BlogsCard;