import React, { useState } from 'react'
import MenuBar from './MenuBar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

const AllEvent = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);


  const [show1, setShow1] = useState(false);

  const [show2, setShow2] = useState(false);

  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);


  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);





  return (
    <>

<Helmet>
        <title> Yugalkunj Canada Hamilton </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

      <MenuBar />

      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer" ></div>

        <div className="auto-container">
          <h1>Upcoming Events</h1>
        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><Link to='/CAD/Home'>Home</Link></li>

              <li className="active"> Upcoming Events </li>
            </ul>
          </div>
        </div>

      </section>





      <section class  Name="causes-section causes-page load-more-section" data-load-number="3" style={{paddingTop:"40px"}}>
        <div className="auto-container">

          <div className="row clearfix">                           




              <div className="cause-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                <div className="image-box">
                  <figure className="image">
                    <a href="#" >
                      <img src="https://yugalkunj.org/images/Ca_yugalkunjclasses.jpg" className="img-height" alt="" />
                    </a>
                  </figure>

                </div>
                <div className="lower-content">
             
                </div>
                <div className="link-box">

                  <button onClick={handleShow4} className="theme-btn btn-style-two">
                    <span className="btn-title">View Flyer</span>
                  </button>
                </div>
              </div>
            </div>  


            <div className="cause-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                <div className="image-box">
                  <figure className="image">
                    <a href="#" >
                      <img src="https://yugalkunj.org/images/satsang.jpg" className="img-height" alt="" />
                    </a>
                  </figure>

                </div>
                <div className="lower-content">



             
                </div>
                <div className="link-box">

                  <button onClick={handleShow} className="theme-btn btn-style-two">
                    <span className="btn-title">View Flyer</span>
                  </button>
                </div>
              </div>
            </div>


            {/* <div className="cause-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                <div className="image-box">
                  <figure className="image"><a href="#" >
                    <img src="https://yugalkunj.org/images/Devotional-Retreat.jpg" className="img-height" alt="" /></a></figure>

                </div>
                <div className="lower-content">

                </div>
                <div className="link-box">

                  <button onClick={handleShow1} className="theme-btn btn-style-two">
                    <span className="btn-title">View Flyer</span>
                  </button>
                </div>
              </div>
            </div> */}


            {/* <div className="cause-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                <div className="image-box">
                  <figure className="image"><a href="#" >
                    <img src="https://yugalkunj.org/images/FamilyRetreat.jpg" className="img-height" alt="" /></a></figure>

                </div>
                <div className="lower-content">



                </div>
                <div className="link-box">

                  <button onClick={handleShow2} className="theme-btn btn-style-two">
                    <span className="btn-title">View Flyer</span>
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div className="cause-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                <div className="image-box">
                  <figure className="image"><a href="#" >
                    <img src="https://yugalkunj.org/images/Toronto_Summer_Camp.png" className="img-height" alt="" /></a></figure>

                </div>
                <div className="lower-content">



                </div>
                <div className="link-box">

                  <button onClick={handleShow3} className="theme-btn btn-style-two">
                    <span className="btn-title">View Flyer</span>
                  </button>
                </div>
              </div>
            </div> */}










          </div>
        </div>
      </section>




      <Footer />


      <Modal show={show} onHide={handleClose} style={{ zIndex: '999999' }}>

        <Modal.Header closeButton>

          <Modal.Title>  Weekly Satsangs!  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </Modal.Title>


          <Button variant="secondary" onClick={handleClose} className='btn btn-danger btn-align float-end'>
            Close
          </Button>



        </Modal.Header>

        <Modal.Body>
          <figure className="image">
            <a src="https://yugalkunj.org/images/satsang.jpg" download>
              <img src="https://yugalkunj.org/images/satsang.jpg" alt=" Winter Family Retreat" />
            </a>
          </figure>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal show={show4} onHide={handleClose4} style={{ zIndex: '999999' }}>

<Modal.Header closeButton>

  <Modal.Title>  Yugal Kunj Classes   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</Modal.Title>


  <Button variant="secondary" onClick={handleClose4} className='btn btn-danger btn-align float-end'>
    Close
  </Button>



</Modal.Header>

<Modal.Body>
  <figure className="image">
    <a src="https://yugalkunj.org/images/Ca_yugalkunjclasses.jpg" download>
      <img src="https://yugalkunj.org/images/Ca_yugalkunjclasses.jpg"  />
    </a>
  </figure>

</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose4}>
    Close
  </Button>

</Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} style={{ zIndex: '999999' }}>

        <Modal.Header closeButton>

          <Modal.Title> Devotional Retreat  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</Modal.Title>


          <Button variant="secondary" onClick={handleClose1} className='btn btn-danger btn-align float-end'>
            Close
          </Button>



        </Modal.Header>

        <Modal.Body>
          <figure className="image">
            <a src="https://yugalkunj.org/images/Devotional-Retreat.jpg" download>
              <img src="https://yugalkunj.org/images/Devotional-Retreat.jpg" alt=" " />
            </a>
          </figure>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal show={show2} onHide={handleShow3} style={{ zIndex: '999999' }}>

        <Modal.Header closeButton>

          <Modal.Title>  Family Day Weekend Retreat   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;</Modal.Title>


          <Button variant="secondary" onClick={handleClose2} className='btn btn-danger btn-align float-end'>
            Close
          </Button>



        </Modal.Header>

        <Modal.Body>
          <figure className="image">
            <a src="https://yugalkunj.org/images/FamilyRetreat.jpg" download>
              <img src="https://yugalkunj.org/images/FamilyRetreat.jpg" alt=" " />
            </a>
          </figure>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>



      <Modal show={show3} onHide={handleShow3} style={{ zIndex: '999999' }}>

<Modal.Header closeButton>

  <Modal.Title>  Toronto Summer Camp   &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;</Modal.Title>


  <Button variant="secondary" onClick={handleClose3} className='btn btn-danger btn-align float-end'>
    Close
  </Button>



</Modal.Header>

<Modal.Body>
  <figure className="image">
    <a src="https://yugalkunj.org/images/Toronto_Summer_Camp.png" download>
      <img src="https://yugalkunj.org/images/Toronto_Summer_Camp.png" alt=" " />
    </a>
  </figure>

</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose3}>
    Close
  </Button>

</Modal.Footer>
</Modal>

    </>
  )
}

export default AllEvent
