import {Link} from 'react-router-dom'
const ServiceCards = (props) => {

  return (


    <>
      <div className="cause-block1 col-lg-4 col-md-6 col-sm-12">
        <div className="inner-box1 wow fadeInUp animated"  >
          <div className="image-box1">
            <figure className="image1">
              <Link to={props.Link} >
                <img src={props.imgsrc} alt="" />
              </Link>
            </figure>

          </div>
          <div className="lower-content1">
            <h3><Link to={props.Link}>  {props.title}</Link></h3>

            <div className="text1">

              {props.Desc}
              <Link to={props.Link} className="readmore"> {props.Readbtn}</Link>



            </div>
            <Link to={props.RegistrationLink} className="theme-btn1 btn-style-two1" target={props.target}>
              <span className="btn-title1">
                <span className="icon fa fa-sign-in-alt" aria-hidden="true">
                </span> {props.registerbtn} </span>
            </Link>
          </div>



        </div>


      </div>

    </>


  )

}

export default ServiceCards;