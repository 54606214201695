import Footer from "./Component/Footer";
// import Header from "./Component/Header";

import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container';

// import OurEventCards from './Component/OurEventCards';
import OverEventTitleCards from './Component/OverEventTitleCards';
// import EventSchedules from './Component/EventSchedules';
import OurEventData from './Component/OurEventData';
import MenuBar from "./Component/MenuBar";



const OurEvent = () => {

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
      };
    
      const currentDate = new Date();
    
      // Filter events based on end date
      const upcomingEvents = OurEventData.filter(event => parseDate(event.enddate) >= currentDate);
    
      // Organize events by date
      const eventsByDate = {};
      upcomingEvents.forEach(event => {
        const dateKey = event.enddate; // You may want to format this as needed
        if (!eventsByDate[dateKey]) {
          eventsByDate[dateKey] = [];
        }
        eventsByDate[dateKey].push(event);
      });



    return (

        <>


            <MenuBar />
            {/* breadcrumb start here */}
            <section className="page-banner">
                <div className="image-layer"></div>
                <div className="upper-icon1"></div>
                <div className="auto-container">
                    <h1>Upcoming Events</h1>
                </div>
                <div className="upper-icon1"></div>
                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">Events</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/*  breadcrum end here */}

            <Container>


            {Object.entries(eventsByDate).map(([date, events]) => (
                <div key={date}>
                    
                    {events.map((event) => (
                    <OverEventTitleCards
                        key={event.id}
                        title={event.title}
                        links={event.links}
                        lablename={event.lablename}
                        enddate={event.enddate}
                        imgscr={event.imgsrc}
                        imglinks={event.imglinks}
                    />

                    
                    ))}
          </div>
        ))}
                {/* {OurEventData.map((val, index) => {
                    return (

                        <OverEventTitleCards
                            key={val.id}
                            title={val.title}
                            links={val.links}
                            pagelink={val.pagelinks}
                            lablename={val.lablename}

                            imgscr={val.imgsrc}
                            imglinks={val.imglinks}

                        />


                    )

                })} */}





            </Container>




            <Footer />
        </>


    )




}

export default OurEvent;