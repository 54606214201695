const Data =[
    {
        id:1,
        image:"https://stssevastorage.blob.core.windows.net/retreat/r1.JPG",
        title:"HTML Tutoril",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"backend",
       
    },
    {
        id:2,
        image:"https://stssevastorage.blob.core.windows.net/retreat/r2.JPG",
        title:"CSS Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"frontend",
       
    },
    {
        id:3,
        image:"https://stssevastorage.blob.core.windows.net/retreat/r2.JPG",
        title:"JS Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"backend",
       
    },
    {
        id:4,
        image:"https://stssevastorage.blob.core.windows.net/retreat/r2.JPG",
        title:"React Js Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"frontend",
       
    },
    {
        id:5,
        image:"./images/bootstrap.jpg",
        title:"Bootstrap Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"frontend",
       
    },
    {
        id:6,
        image:"./images/php.jpg",
        title:"PHP Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"backend",
       
    },
    {
        id:7,
        image:"./images/node-js.jpg",
        title:"Node.js Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"backend",
       
    },
    {
        id:8,
        image:"./images/asp.jpg",
        title:"ASP Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"backend",
       
    },
    {
        id:9,
        image:"./images/sql.jpg",
        title:"SQL Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"database",
       
    },
    {
        id:10,
        image:"./images/mongodb.jpg",
        title:"Mongo DB Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"database",
       
    },
    {
        id:11,
        image:"./images/c.jpg",
        title:"C Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"programming",
       
    },
    {
        id:12,
        image:"./images/c-plus.jpg",
        title:"C++ Tutorial",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
        category:"programming",
       
    }
   
    
]

export default Data;