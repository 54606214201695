 import WhatWeCard from './WhatWeCard';
 import WhatWeDoData from './WhatWeDoData';

const WhatWeDo = (props) => {
 return(

    // <!--What We Do-->
    <section className="what-we-do">
        {/* <div className="image-layer" style={{backgroundImage:url('https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/icons/featured-bg-pattern-2.png}}>
        </div> */}

<div className="image-layer" >
        </div>
        <div className="auto-container">
            <div className="sec-title centered">
                <div className="upper-icon"></div>
                <h2>What We Are Doing</h2>
                {/* <!-- <div className="text">We believe what we achieve</div> --> */}
            </div>

            <div className="row clearfix">
                {/* <!--Service Block--> */}
               {/* <WhatWeCard
                imgicon='flaticon-therapy'
                Link="https://yugalkunj.org/words-of-wisdom.html"
                title="Words Of Wisdom"
                Desc="Words of Wisdom is inspirational message sent out by Didi Ji every Friday. To receive weekly emails of Words of Wisdom"
                 Readtext="Read More"
               /> */}
        



        {WhatWeDoData.map( (val, index) =>{

            
    return (


     
         <WhatWeCard 
          key={val.id}
          imgicon={val.imgicon}
          Link={val.Link}     
          title={val.title} 
                       
          Desc={val.Desc} 
         
          Readtext ={val.Readtext} 
        
       />
    );

  })}
                
              
               
            </div>
        </div>
    </section>

      


 )
   

 


}

export default WhatWeDo ;