import MenuBar from "./Component/MenuBar";
import { Link } from 'react-router-dom'
import BlogsCard from './Component/BlogsCard'
import BlogsData from './Component/BlogsData'
import Footer from './Component/Footer';
const Blogs = (props) => {
    return (
        <>
            <MenuBar />



            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Blogs</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/USA/Blogs'>Home</Link></li>

                            <li className="active"> BLOGS</li>
                        </ul>
                    </div>
                </div>

            </section>



            <section className="causes-section causes-page load-more-section" >
                <div className="auto-container">

                    <div className="row clearfix">

                        {/* <!--Cause Block--> */}

                        {
                            BlogsData.map((val, index) => {
                                return (

                                    <BlogsCard
                                        key={val.id}
                                        imgsrc={val.imgsrc}
                                        Linkink={val.Link}
                                        title={val.title}
                                        EventDate={val.EventDate}


                                        Desc={val.Desc}

                                       



                                    />
                                )


                            })

                        }




                    </div>

                    {/* <div className="load-more"><a href="#" className="theme-btn load-more-btn">load more</a></div> */}

                </div>
            </section>

            <Footer />

        </>


    )

}


export default Blogs;
