
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import EventCarousel from './EventCarousel'
import { Link } from 'react-router-dom';

const OverEventTitleCards = (props) => {
    return (
        <>


            <div className="auto-container">

                <div className="centered" style={{ marginTop: '15px' }}>

                    <h3> {props.title}  </h3>
                    <hr />
                    <a href={props.links} style={{ color: 'darkblue !important' }} className="alert alert-primary">
                        <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  {props.lablename}</span>
                    </a>
                    <hr />
                </div>
            </div>

            <Row>

                <Col>

                    <div className="card card-margin">
                        <div className="card-header no-border">
                            <Link to={props.pagelinks}  > <h5 className="card-title"> {props.title} </h5></Link>

                        </div>
                        <div className="card-body pt-0">
                            <div className="widget-49">



                                <figure className="image">
                                    <img src={props.imgscr} alt="" width="100%" />
                                    <a href={props.links} className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                                </figure>
                                <br />
                                <div className="widget-49-meeting-action">
                                    <a href={props.imglinks} target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                                </div>
                            </div>
                        </div>
                    </div>



                </Col>



            </Row >


            {/* 
            <section className="coming-events">

                <div className="auto-container">
                    <div className="sec-title centered">

                        <h2> Grand Janmashtami Schedules </h2>



                    </div>



                    <EventCarousel />


                </div>
            </section> */}


        </>


    )
}

export default OverEventTitleCards;

