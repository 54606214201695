// import GalleryCard from "./GalleryCard"
import GalleryCard from "./Component/GalleryCard";
import GalleryCardData from "./Component/GalleryCardData";
// import Header  from "./Component/Header";
import Footer from "./Component/Footer";
import MenuBar from "./Component/MenuBar";
import { Link } from 'react-router-dom'

import { Helmet } from "react-helmet";
const OurGallery = () => {
    return (
        <>

       <Helmet>
        <title> Yugalkunj Canada Hamilton </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

            <MenuBar />
            {/* <Header/> */}
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Our Gallery</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>

                            <li className="active"> Our Gallery</li>
                        </ul>
                    </div>
                </div>

            </section>



            <section className="activity-section">

                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="upper-icon"></div>
                        <h2>Our Gallery</h2>
                        {/* <!-- <div className="text">We believe what we achieve</div> --> */}
                    </div>

                    <div className="mixit-gallery">
                        {/* <!--Filter--> */}



                        <div className="filter-list row clearfix" id="MixItUp7B5F74">



                            {/* <GalleryCard 
              imgsrc="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
              Link="https://stssevastorage.blob.core.windows.net/retreat/r1.JPG"
            /> */}

                            {GalleryCardData.map((val, index) => {
                                return (

                                    <GalleryCard
                                        key={val.id}

                                        imgsrc={val.imgsrc}
                                        Link={val.Link}


                                    />
                                );

                            })}



                        </div>


                        {/* <div className="bottom-info">

                            <div className="link-box"><a href="gallery.html" className="theme-btn btn-style-five"><span className="btn-title">View More Activities</span></a></div>
                        </div> */}

                    </div>
                </div>
            </section>

            <Footer />

        </>

    )

}

export default OurGallery;