import Footer from "./Component/Footer";
import MenuBar from "./Component/MenuBar";
import { NavLink } from "react-router-dom";

const ActivityCalendar = () => {
    return (
        <>

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Activity Calendar</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active">Activity Calendar</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">
                    <div className="offset-1">


</div>

                        <div className="col-md-10 offset 1">
                            <img src="https://yugalkunj.org/images/ykcalender.jpeg" className="img-round " style={{ border: '1px solid #ccc' }} alt="yk calender" />

                            {/* <!-- <iframe id="gcalendar_frame" src="https://www.google.com/calendar/embed?showTitle=1&amp;showNav=1&amp;showDate=1&amp;showPrint=1&amp;showTabs=1&amp;showCalendars=0&amp;showTz=1&amp;mode=MONTH&amp;wkst=2&amp;bgcolor=%23FFFFFF&amp;hl=&amp;ctz=America/New_York&amp;height=500&amp;src=i4ot1nkv9bsnvk34bikit77vks@group.calendar.google.com&amp;color=%23A32929&amp;src=i4ot1nkv9bsnvk34bikit77vks@group.calendar.google.com&amp;color=%23B1440E" width="100%" height="500" align="top" frameborder="0" className="gcalendar"> </iframe> -->  */}
                        </div>

                        


                    </div>


                </div>
            </section>






            <Footer />


        </>


    )



}

export default ActivityCalendar;