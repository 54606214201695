const GalleryCardData = [
    {
        id: 1,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/42.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/42.jpg",



    },


    {
        id: 2,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/54.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/54.jpg",

    },


    {
        id: 3,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/55.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/55.jpg",

    },


    {
        id: 4,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/55.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/55.jpg",

    },


    {
        id: 5,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/53.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/53.jpg",

    },


    {
        id: 6,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/52.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/52.jpg",

    },


    {
        id: 7,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/51.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/51.jpg",

    },


    {
        id: 8,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/50.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/50.jpg",

    },


    {
        id: 9,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/46.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/46.jpg",

    },

    {
        id: 10,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/48.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/48.jpg",

    },

    {
        id: 11,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/21.jpg",
        Link: "https://stssevastorage.blob.core.windows.net/ykwebsitecanada/21.jpg",

    },

    {
        id: 12,
        imgsrc: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/2.jpg",
        Link: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/2.jpg",

    },



    {
        id: 13,
        imgsrc: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/3.jpg",
        Link: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/3.jpg",

    },

    {
        id: 14,
        imgsrc: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/4.jpg",
        Link: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/4.jpg",

    },

    {
        id: 15,
        imgsrc: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/5.jpg",
        Link: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/5.jpg",

    },


    {
        id: 16,
        imgsrc: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/6.jpg",
        Link: "https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/gallery/6.jpg",

    },




]

export default GalleryCardData;