// import Header from './Component/Header';
import Footer from './Component/Footer';
import MenuBar from './Component/MenuBar';
import { NavLink } from 'react-router-dom'
import WordofWisdomeCard from '../WordofWisdomeCard'


const WordsOfWisdom = () => {
    return (
        <>


            {/* <Header/> */}

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>Words of Wisdom</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">
                                Words of Wisdom 
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--Sidebar Page Container--> */}
         <WordofWisdomeCard/>
            {/* <!-- End Sidebar Page Container --> */}




            <Footer />

        </>


    )


}

export default WordsOfWisdom;