
import React from 'react'
import MenuBar from "../Component/MenuBar";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";

const Overnightcamp = () => {
  return (
    <>

      <MenuBar />
      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer" ></div>

        <div className="auto-container">
          <h1>Family Overnight Camping  </h1>
        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/USA/Home">Home</NavLink></li>

              <li className="active"> Family Overnight Camping </li>
            </ul>
          </div>
        </div>

      </section>
      {/* <!--End Banner Section --> */}

      <div className="container">



        <div className="auto-container">

          <div className="centered">
            <hr />
            <h3>Family Overnight Camping  </h3>
            <hr />
            <a href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank" className="alert alert-primary">
              <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
            </a>
            <hr />
          </div>
        </div>




        <div className="row">




          <div className="col-lg-12">
            <div className="card card-margin">
              <div className="card-header no-border">
                <h5 className="card-title">  Family Overnight Camping </h5>

              </div>
              <div className="card-body pt-0">
                <div className="widget-49">


                  <figure className="image">
                    <img src="https://yugalkunj.org/images/Family%20Overnight%20Camp%20Retreat.png" alt="" />
                    <a href="https://yugalkunj.org/images/Family%20Overnight%20Camp%20Retreat.png" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                  </figure>
                  <br />
                  <div className="widget-49-meeting-action">
                    <a href="https://yugalkunj.org/images/Family%20Overnight%20Camp%20Retreat.png" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                  </div>



                  <hr />

                  <div>




                

                  </div>



                </div>
              </div>
            </div>
          </div>




        </div>




        <section className="coming-events">

<div className="auto-container">
  <div className="sec-title centered">

    <h2>  Family Overnight Camping Schedules </h2>



  </div>

  <div  className='row '>
       <div className='col-md-12'>
       <div className="panel-group">
  <div classname="panel panel-primary">
    <div className="panel-body" style={{textAlign:'center'}}>   
             

              <p><u>Guided by Siddheshvari Devi (Didi Ji)</u></p>

              <p> Friday, June 28th 6pm - Saturday, June 29th at 9am</p>

              <p>*Parents need to be on the campsite with Kids* </p>

              <div className='col-md-12'>

                <h4>Friday Evening</h4>

                <ul className='shedule_list'>
                  <li>6:00 pm - 6:55 pm - Bhog (Food) Offer followed by Dinner</li>
                  <li>7:00 pm - 8:30 pm - Aartis & Outdoor Satsang with Didi Ji</li>
                  <li>8:30 pm - 10:30 pm - Food, Games, Chanting  </li>
                 

                </ul>

              </div>

              <div className='col-md-12'>

<h4>Saturday morning</h4>

<ul className='shedule_list'>
  <li>7:00 am - Bhog & Aarti</li>
  <li>7:00 am - 9:00 am - Weekly Saturday Satsang</li>
  <li>Followed by Breakfast  </li>
 

</ul>

</div>


<div className='col-md-12'>

<h4>What to bring for the overnight camp</h4>

<ul className='shedule_list'>
  <li>Tent</li>
  <li>Sleeping bag</li>
  <li>Padding to sleep on (foam roll or something similar) </li>
  <li>Water bottle </li>
  <li>A small pillow or an article of clothing that can be used to roll into a ball and used as a pillow. </li>


 

</ul>

</div>


    </div>
  </div>
  
</div>


       </div>



  </div>

  </div>

  </section>


       
         {/* <section className="coming-events">

          <div className="auto-container">
            <div className="sec-title centered">

              <h2>  Devotional Retreat Schedules </h2>



            </div>



            <Carousel variant="dark">

            <Carousel.Item >
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week1.jpg"
                  alt="Second slide"
                />

              </Carousel.Item>

              <Carousel.Item >
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week2.jpg"
                  alt="Second slide"
                />

              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week3.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week4.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week5.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week6.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>



            </Carousel>



          </div>
        </section>  */}
















      </div>















































      <Footer />
    </>
  )
}

export default Overnightcamp






