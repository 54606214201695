import React from 'react'
import MenuBar from './MenuBar'
import Footer from './Footer'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <>

            <MenuBar />



            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Privacy Policy</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>
                            <li className="active">Privacy Policy</li>
                        </ul>
                    </div>
                </div>

            </section>

            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">

                            <h4 className='text-center'> Radha Madhav Society <br/>
                                Operating As (O/A) Yugal Kunj</h4>

                            <br />


                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Privacy Policy</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="text">
                                            <p><strong>Effective Date: August 1, 2022</strong></p>

                                            <h4> 1. Introduction</h4>

                                            <p> Welcome to Radha Madhav Society, operating as Yugal Kunj. We are deeply committed to
                                                safeguarding your privacy and the security of your personal information. This Privacy Policy
                                                outlines how we collect, use, disclose, and protect your information when you visit our place of
                                                worship or engage with us online.</p>


                                            <h4>2. Information We Collect</h4>

                                            <p> We may collect the following categories of personal information:</p>
                                            <ul className="satsang_list">

                                                <li>Contact Information: Including your name, email address, phone number, and mailing
                                                    address.</li>
                                                <li> Demographic Information: Such as age, gender, and family details.</li>
                                                <li>Event Participation: Details related to your participation in our events, services, and
                                                    other activities.</li>
                                                <li>Financial Information: Payment information for donations, tithes, or other
                                                    contributions.</li>
                                                <li>Online Activity: Data related to your interactions with our website, including IP
                                                    addresses, cookies, and browsing behaviour.</li>
                                                <li>Media: Photos and videos taken during events, services, and other activities that may
                                                    include your image or likeness.</li>




                                            </ul>



                                            <h4>3. How We Use Your Information</h4>

                                            <p>Your personal information is used for the following purposes:</p>
                                        </div>

                                        <ul className="satsang_list">
                                            <li> Service Facilitation: To enable your participation in services, events, and activities.</li>
                                            <li> Communication: To provide you with newsletters, updates, and other communications
                                                relevant to our community.</li>

                                            <li> Event Documentation: To capture and share memories of our community by posting
                                                photos and videos of events and services on our website, social media, and other
                                                platforms.</li>
                                            <li> Financial Management: To process and manage donations and other financial
                                                contributions.</li>

                                        </ul>


                                        <h4>4. Disclosure of Your Information</h4>

                                        <p>We may share your personal information under the following circumstances:</p>


                                        <ul className="satsang_list">

                                            <li>Service Providers: With third-party service providers who assist us in operating our
                                                place of worship and website.</li>
                                            <li>Legal Compliance: When required by law or in response to valid requests from public
                                                authorities.</li>
                                            <li>Public Media Sharing: Photos and videos taken during events may be shared publicly
                                                on our website, social media, and other platforms. If you prefer not to be included in the
                                                media, please inform us in advance.</li>



                                        </ul>

                                        <h4>5. Data Security</h4>

                                        <p> We prioritize the security of your personal information and have implemented appropriate
                                            technical and organizational measures to protect it from unauthorized access, disclosure,
                                            alteration, or destruction.</p>

                                        <h4> 6. Your Rights</h4>

                                        <p> You have the following rights regarding your personal information:</p>

                                        <ul className="satsang_list">
                                            <li>

                                                Access and Correction: You may request access to or correction of your personal
                                                information.
                                            </li>



                                            <li>Media Opt-Out: If you do not wish to be photographed or recorded during events,
                                                please inform us, and we will make every effort to respect your preferences.</li>

                                            <li> Deletion: You may request to delete your personal information, subject to legal and
                                                operational requirements.</li>


                                        </ul>

                                        <h4> 7. Cookies and Tracking</h4>

                                        <p>
                                            Our website uses cookies and other tracking technologies to enhance your user experience and
                                            analyze usage patterns. You can control cookies through your browser settings.
                                        </p>

                                        <h4>8. Changes to This Privacy Policy</h4>

                                        <p> This Privacy Policy may be updated periodically. Any significant changes will be communicated
                                            by posting the updated policy on our website, with the effective date prominently displayed.</p>


                                        <h4>
                                            9. Contact Us
                                        </h4>

                                        <p> If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us
                                            at:</p>


                                        <h4>Yugal Kunj</h4>

                                        <address>


                                        </address>
                                        <p>375 Aberdeen Avenue
                                            Hamilton, ON L8P 2R7</p>

                                        <p> <strong> Phone:</strong> 289-389-5311</p>

                                        <p><strong> Email:</strong>  info.ca@yugalkunj.org</p>









                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>



            <Footer />
        </>
    )
}

export default PrivacyPolicy
