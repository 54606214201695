import MenuBar from "../Component/MenuBar";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-bootstrap";

const SummerCamp =()=>{
  return(
   <>
    
    <MenuBar />
      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer"></div>

        <div className="auto-container">
          <h1>Summer  Camp </h1>
        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/USA/Home">Home</NavLink></li>

              <li className="active">Summer Camp</li>
            </ul>
          </div>
        </div>

      </section>
      {/* <!--End Banner Section --> */}

      <div className="container">



        <div className="auto-container">

          <div className="centered">
            <hr />
            <h3> Summer Camp </h3>
            <hr />
            <a href="https://ykportal.yugalkunj.org" target="_blank" className="alert alert-primary">
              <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
            </a>
            <hr />
          </div>
        </div>

        <div className="row">

          <div className="col-lg-12">
            <div className="card card-margin">
              <div className="card-header no-border">
                <h5 className="card-title"> Summer Camp</h5>

              </div>
              <div className="card-body pt-0">
                <div className="widget-49">


                  <figure className="image">
                    <img src="https://yugalkunj.org/images/Summer_camp_2024.png" alt="" />
                    <a href="https://yugalkunj.org/images/Summer_camp_2024.png" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                  </figure>
                  <br />
                  <div className="widget-49-meeting-action">
                    <a href="https://yugalkunj.org/images/Summer_camp_2024.png" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>



      </div>


      <hr />
         <section className="coming-events">

          <div className="auto-container">
            <div className="sec-title centered">

              <h2>  Summer Camp Schedules </h2>



            </div>



            <Carousel variant="dark">

            <Carousel.Item >
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/SC/WK%202%20SC%2024%27%20-%20Parent%27s%20Schedule%20-%2010-14.png"
                  alt="Second slide"
                />

              </Carousel.Item>

             


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/SC/WK%202%20SC%2024%27%20-%20Parent%27s%20Schedule%20-%207%20-9.png"
                  alt="Third slide"
                />


              </Carousel.Item>

           


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/SC/WK%202%20SC%2024%27%20-%20Parent%27s%20Schedule%20-%203-6.png"
                  alt="Third slide"
                />


              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/SC/Week%202%20Menu%20jpeg.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>



            </Carousel>



          </div>
        </section> 
      <Footer />
   
   
   </>



  )

}

export default SummerCamp;