import React from 'react'
import MenuBar from './Component/MenuBar';
import Footer from './Component/Footer';
import { Link, NavLink } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';
const YugalKunjexpansion = () => {
  return (
    <>

    <MenuBar/>

  {/* <!-- Page Banner Section --> */}
  <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>Yugal Kunj expansion </h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>

                            <li className="active">Yugal Kunj expansion</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}


            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">

                            {/* <h4 className='text-center'> Radha Madhav Society <br/>
                                Operating As (O/A) Yugal Kunj</h4> */}

                            {/* <br /> */}


                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Yugal Kunj expansion</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">


                                    <Carousel variant="dark" interval={1000}>

<Carousel.Item >
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/1.jpg"
        alt="Second slide"
    />

</Carousel.Item>

<Carousel.Item >
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/2.jpg"
        alt="Second slide"
    />

</Carousel.Item>


<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/3.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/4.jpg"
        alt="Third slide"
    />


</Carousel.Item>


<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/5.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/6.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/7.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/8.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/9.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/10.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/11.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/12.jpg"
        alt="Third slide"
    />


</Carousel.Item>

<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/13.jpg"
        alt="Third slide"
    />


</Carousel.Item>


<Carousel.Item>
    <img
        className="d-block w-100"
        src="https://yugalkunj.org/images/YugalKunjexpansion/15.jpg"
        alt="Third slide"
    />


</Carousel.Item>





</Carousel>

                                        
                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>
    
      <Footer/>
    </>
  )
}

export default YugalKunjexpansion
