import MenuBar from "./Component/MenuBar";
import Footer from "./Component/Footer";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { Helmet } from 'react-helmet';


const Contact = () => {
//   const [ContactUs, SetContactUs] = useState({
//     name:'',
//     phone:'',
//     email:'',
//     message:''

//   });

//   const handleInut = (e) => {
//     e.preventDefault();
//     const name = e.target.name;
//     const value = e.target.value;
//     SetContactUs({ ...ContactUs, [name]: value });
//   };





    return (
        <>
        <Helmet>
        <title> Yugalkunj Canada Hamilton </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Contact US</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>

                            <li className="active"> Contact US </li>
                        </ul>
                    </div>
                </div>

            </section>




            {/* <!--Contact Section--> */}
            <section className="contact-section contact-page" style={{marginTop:"20px"}}>




                <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-md-4 offset-4 ">
                        <h3 className="text-center"> Leave A Message</h3>
                        <p className="text-center">
                            <a href="https://ykportal.yugalkunj.org/Home/Canada_Contact"  className="theme-btn btn-style-one" style={{width: '300px', textAlign: 'center', color: 'rgb(255, 255, 255)'}}>
                                <span className="btn-title"> Please Click Here</span></a></p>
                                </div>
                        </div>


                    <div className="row clearfix">

                        {/* <div className="form-column col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{marginTop:'20px'}}>
                           

                               <div className="sec-title centered"><div className="upper-icon1"></div><h2> Leave A Message  </h2></div>
                           
                            <div className="inner">

                                <div className="default-form contact-form">
                                    <form method="post" action="" id="contact-form" >
                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-12 form-group">
                                                <div className="field-label"> Name</div>
                                                <input type="text" name="name" value={ContactUs.name}  required="" onChange={handleInut} />
                                            </div>



                        {/* <div className="form-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="default-form contact-form">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="inner-button text-center">


                                            <a href="https://ykportal.yugalkunj.org/home/contact" target="_blank" className="theme-btn btn-style-one" style={{ width: '300px', textAlign: 'center', color: '#fff' }}><span className="btn-title"> Please Click Here</span></a>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}
                        {/* <!--Image Column---> */}


                    </div>

                    <div className="info-container">
                        <div className="row clearfix">

                            {/* <!--Info Block--> */}
                            <div className="info-block bg-light-blue col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="inner">
                                    <h3>Yugal Kunj  Canada</h3>
                                    <ul className="info">
                                        <li><span className="fa fa-map-marker-alt"></span> 375 Aberdeen Ave, Hamilton, ON L8P 2R7, Canada</li>
                                        <li><span className="fa fa-phone-alt"></span> <a href="tel:+1 (289) 389-5311">Phone +1 (289) 389-5311</a></li>
                                        <li><span className="fa fa-envelope-open"></span> <a href="mailto:info.ca@yugalkunj.org">info.ca@yugalkunj.org</a></li>


                                    </ul>
                                    <ul className="social-links clearfix">
                                        <li><a href="https://www.facebook.com/yugalkunjcanada" target="_blank"><span className="fab fa-facebook-f"></span></a></li>


                                        <li><a href="https://www.instagram.com/yugalkunj_canada/" target="_blank"><span className="fab fa-instagram"></span></a></li>
                                        {/* <li><a href="#"><span className="fab fa-youtube"></span></a></li> */}
                                    </ul>

                                    {/* <a href="https://ykportal.yugalkunj.org/home/contact" target="_blank" className="theme-btn btn-style-one" style={{ width: '300px', textAlign: 'center', color: '#fff' }}><span className="btn-title"> Please Click Here</span></a> */}

                                </div>
                            </div>

                            <div className="info-block bg-blue col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                 <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.065578691638!2d-79.89234328539278!3d43.25004738642558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b6c61cff5eb%3A0xec2d7e761feaf66f!2s375%20Aberdeen%20Ave%2C%20Hamilton%2C%20ON%20L8P%202R7%2C%20Canada!5e0!3m2!1sen!2sin!4v1679310012445!5m2!1sen!2sin"
                                    width="100%"
                                    height="320"
                                    frameborder="0"

                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                >

                                </iframe> 
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <Footer />




        </>

    )


}

export default Contact;