

const EventCard = (props) => {
  return (
   <>
    {/* <!--Cause Block--> */}
    <div className="cause-block alternate col-lg-4 col-md-6 col-sm-12">
                        <div className="inner-box wow fadeInUp" data-wow-delay="0ms">
                            <div className="image-box">
                                <figure className="image"><a href="#">
                                    <img src={props.imgsrc} alt=""/></a></figure>
                                <div className="progress-box">
                                    {/* <!-- <div className="bar">
                                    <div className="bar-inner count-bar" data-percent="80%"><div className="count-text">80%</div></div>
                                </div> --> */}
                                </div>
                            </div>
                            <div className="lower-content">
                                <h3><a href={props.Link}>  { props.title} </a></h3>
                                {/* <!-- <div className="cause-title">Medicine</div> --> */}
                                <div className="text">  { props.Desc}</div>
                                {/* <!-- <div className="donate-info clearfix">
                                <div className="goal"><i className="fa fa-crosshairs"></i> <span>Goal:</span> <strong>$10,000</strong> </div>
                                <div className="raised"><i className="fa fa-chart-line"></i> <span>Raised:</span> <strong>$6,500</strong> </div>
                            </div> --> */}
                            </div>
                        </div>
                    </div>
   </>

  )


}

export default EventCard; 