// import Header from "./Component/Header"; 
import MenuBar from './Component/MenuBar';
import Footer from './Component/Footer';
import { Link, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const AboutPage = () => {
    return (

        <>
      <Helmet>
        <title> Yugalkunj Canada Hamilton </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>
            {/* <Header/> */}

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>About Us  </h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>

                            <li className="active">About</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            {/* <!--About Section--> */}
            <section className="about-section">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Left Column--> */}
                        <div className="left-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span> </div>
                                    <h2>Welcome To Yugal Kunj</h2>
                                </div>
                                <div className="lower-box">
                                    {/* <!--<h4>Meaning Of The Name</h4>--> */}
                                    <h4>Meaning of Yugal Kunj</h4>

                                    <div className="text">
                                        <p>The name was given by our Gurudev, Jagadguru Shri Kripalu Ji Maharaj.
                                           &nbsp; The meaning of YUGAL KUNJ is the ‘flowery pleasure groves of Vrindavan where Radha and Krishna roam leisurely and perform loving pastimes.’</p>
                                        <h4>Aim Of Yugal Kunj</h4>

                                        <p>


                                        </p>

                                        <p> Spread over one full city block of downtown Hamilton, Yugal Kunj brings religion and culture to people of all ages. Yugal Kunj provides a temple for practicing devotion, a community center for the personal development of children and adults, and a second home where families can spend quality time together in a spiritual and socially uplifting environment.</p>
                                        {/* 
                                    <p>
                                        Spread over 15 acres of lush green landscape located Duluth Atlanta, Yugal Kunj is dedicated  to bring religion and faith to people of all ages.
                                        Yugal Kunj aspires to provide a temple for practicing devotion; a community center for the personal development of children and adults,
                                        and a second home where families can spend quality time together in a picturesque serene surrounding.
                                    </p> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <!--Right Column--> */}
                        <div className="right-column col-lg-5 col-md-12 col-sm-12">
                            <div className="about-feature-box">
                                <div className="outer clearfix">
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    {/* <!-- <h3><a href="#">Truly Get Inspired From Us</a></h3>--> */}

                                                    <h3><a href="#">Get Truly Inspired By Us</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><NavLink to='#'>Become a Genuine Volunteer</NavLink></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><a href="https://donation.yugalkunj.org/">Make a Donation</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--About Feature--> */}
                                    <div className="about-feature col-md-6 col-sm-12">
                                        <div className="inner-box">
                                            <div className="content-box">
                                                <div className="inner">
                                                    <h3><a href="#">Others</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!--  secton section --> */}


                </div>

            </section>
            <section className="about-section-two">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    {/* <!--  <div className="sub-title"><span className="icon"></span> We believe what we achieve</div> --> */}
                                    <h2>About The Organization</h2>
                                </div>
                                <div className="lower-box">

                                    <div className="text">
                                        Radha Madhav Society (RMS) is a branch of Jagadguru Kripalu Parishat (JKP). &nbsp; JKP is our parent organization in India, functioning under the loving guidance of Jagadguru Shri Kripalu Ji Maharaj. &nbsp; RMS is a charitable non-profit organization which was founded by Siddheshvari Devi Ji in 1997. Radha Madhav Society’s main objective is to increase spiritual awareness and help the needy in society.
                                        <br/>
                                      <br/>

                                        <p> The temple Yugal Kunj  was established by Didi Ji, a senior preacher disciple of Shri Maharaj Ji. Didi Ji has taken RMS and the teachings of Shri Maharaj JI to the US, Canada, Trinidad and other parts of the world.</p>


                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft animated" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <figure className="image-box">
                                    <img src="https://stssevastorage.blob.core.windows.net/ykwebsitecanada/torento1.jpg" alt="" /></figure>
                            </div>
                        </div>
                    </div>


                    <div className="row clearfix">
                        <div className="col-md-12">

                            <div className="lower-box">


                                <p> Yugal Kunj presents the authentic teachings of the Vedas in a modern and interesting way. &nbsp;
                                    The temple is located in a very established and beautiful part of downtown Hamilton. &nbsp;
                                    This building has been a place of worship in this neighbourhood for the last fifty years:
                                    we are renovating and transforming it into a modern Hindu temple. &nbsp;
                                    The main Prayer Hall will house the main shrine for the beautiful life sized deities of Radha Krishna,
                                    Seeta Ram, Kirti Ma (The Divine Mother of Radha Rani), and Jagadguru Shree Kripalu Ji Maharaj.&nbsp;
                                    In addition, the deities of the Ashta Maha Sakhis (the eight main friends of Radha Rani) and the four authentic Jagadgurus who preceded Shri Maharaj Ji (the fifth original and authentic Jagadguru ) will grace the side walls.</p>
                                <h4>Surroundings</h4>

                                <p>
                                    Yugal Kunj is located in a very vibrant and multicultural part of downtown Hamilton and is easily accessible via public transit. &nbsp;
                                    We are only a few blocks from the Locke Street shopping district which is a popular destination for visitors and local residents. &nbsp;
                                    Also, the McMaster University and the McMaster Innovation Center is just a short walk from our temple.
                                </p>





                                <h4>Gurukul</h4>

                                <p>
                                    Gurukul is the educational wing of Yugal Kunj. &nbsp; This exciting initiative will take children and teens step by step through the various levels of Hindu religion and culture, helping them develop a long lasting faith and understanding of the Hindu religion.&nbsp;
                                    At present all Gurukul classes are virtual, however, we are working diligently to build classrooms equipped with state of the art technology that will provide an optimal learning environment. &nbsp;
                                    Dedicated teachers make learning an enjoyable experience for all children.

                                </p>

                                <h4>Projects For Youth</h4>

                                <p> The temple and community center cover a wide variety of projects and subjects for the youth. &nbsp; There is something for everyone.<br />
                                    There are presently various activities already in progress at the center.
                                    Kids can join classes to learn to play various musical
                                    instruments depending on their choice and aptitude. &nbsp; They can learn to play traditional instruments such as Dholak and Harmonium and
                                    untraditional ones such as danthal.</p>

                                <h4> Camps</h4>
                                <p> The purpose of the camps is to foster group activities so that kids may develop a sense of teamwork and a spirit to share as they grow. &nbsp;
                                    Another important aspect of the camp is the opportunity for children to associate with Didi Ji and develop a strong bond with her. &nbsp;
                                    A relationship with your spiritual guide is vital for your spiritual progress. &nbsp;
                                    Several day camps are organized throughout the year to accommodate different schedules.  &nbsp; Camps are fun filled days with water sports and s’mores around the campfire. &nbsp; Older
                                    kids learn various aspects of leadership and project management, providing them with the tools to become better leaders in the future.</p>



                            </div>
                        </div>

                    </div>
                </div>

            </section>


            <section className="call-to-action alternate-two">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-pattern-1.png)' }}></div>

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="title-column col-xl-12 col-lg-12 col-md-12 col-sm-12">

                            <h2 className='text-center'>  Become a vital part of Yugal Kunj &amp;  make a real difference to lives of many!</h2>
                        </div>
                        <div className="link-column col-xl-3 col-lg-12 col-md-12 col-sm-12">
                            <div className="link-box">



                                {/* <NavLink to='/CAD/VolunteerRegistration' className="theme-btn btn-style-one"><span className="btn-title">join to Volunteer</span></NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>


    )


}

export default AboutPage;