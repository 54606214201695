function Items({data}){
    return (
        <div className="row ">
        {
          data.map((value)=>{
           const {id, image, title, description} = value;
           return (
            <div className="col-md-4 my-3" key={id}>
             <div className="card bg-light text-center">
               <a href={image} className="lightbox-image overlay" data-fancybox="gallery-two">
              
                 <img src={image} className="img-responsive" style={{height:"300px"}}/>

                
                 </a>
                 {/* <span className="icon fa fa-search-plus"></span> */}
                
            </div>
         </div>
           )
          })
        }

</div>
    )
}

export default Items;