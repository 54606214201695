import MenuBar from "../Component/MenuBar";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";

import { Carousel } from "react-bootstrap";

const WinterCamp = () => {
  return (

    <>

      <MenuBar />
      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer"></div>

        <div className="auto-container">
          <h1>Kids Winter Camp </h1>
        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/USA/Home">Home</NavLink></li>

              <li className="active">Kids Winter Camp</li>
            </ul>
          </div>
        </div>

      </section>
      {/* <!--End Banner Section --> */}

      <div className="container">



        <div className="auto-container">

          <div className="centered">
            <hr />
            <h3> Kids Winter Camp </h3>
            <hr />
            <a href="https://ykportal.yugalkunj.org" target="_blank" className="alert alert-primary">
              <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
            </a>
            <hr />
          </div>
        </div>

        <div className="row">

          <div className="col-lg-12">
            <div className="card card-margin">
              <div className="card-header no-border">
                <h5 className="card-title">  Kids Winter Camp</h5>

              </div>
              <div className="card-body pt-0">
                <div className="widget-49">


                  <figure className="image">
                    <img src="https://yugalkunj.org/images/Winter_Camp_shedule.jpg" alt="" />
                    <a href="https://yugalkunj.org/images/Winter_Camp_shedule.jpg" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                  </figure>
                  <br />
                  <div className="widget-49-meeting-action">
                    <a href="https://yugalkunj.org/images/Winter_Camp_shedule.jpg" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>



      </div>



      <Footer />
    </>
  )

}
export default WinterCamp;