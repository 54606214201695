import ServiceCards from "./Component/ServiceCards";
import ServiceData from "./Component/ServiceData";
import Footer from './Component/Footer';
import MenuBar from "./Component/MenuBar";



const OurServices = () => {
  return (
  <>

<MenuBar/>
   <section className="causes-section1">
      <div className="icon-one1 paroller1" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal" style={{transform:"translateX(29px)" , transition: "transform 0s linear 0s", willChange: "transform"}}><span className="flaticon-heart-2"></span></div>
  
     
  
      <div className="auto-container">
  
          <div className="sec-title centered">
              <div className="upper-icon1"></div>
              <h2>Yugal Kunj Services  </h2>
  
          </div>
  
          <div className="row row-flex clearfix">
  
    {/* <!--Cause Block--> */}
    
           
          
  
  {ServiceData.map(  (val, index) =>{
    return (

        <ServiceCards 
        key={val.id}
         imgsrc={val.imgsrc} 
         title={val.title}                  
         Desc={val.Desc} 
         Link={val.Link} 
         Readbtn ={val.Readbtn} 
         registerbtn ={val.registerbtn} 
   
   
       />
    );

  })}
  
  
          </div>
  
      </div>
</section>
  
  
  
  
  <Footer/>
  
  </>


  )



}


export default OurServices;