 import axios from 'axios';

 const facebookAPI = axios.create({
  baseURL: 'https://graph.facebook.com/v14.0', // Check the latest version in the Facebook Graph API
});

const accessToken = 'EAAEMFm5W7ggBO8TjgFHSqva0uQafnZASN4ZClTlJVTuNc2jwXZBHqLuyEBR04MlDG18pwOg8Lq052fUprBpf6khGRbSZCnCFlwSCUxOpn8hCWRa753gqLshLfmNRiba1DSVgZCY8MG3Q50EyKecZA3tsULZCgZAf55SXWSqCpmOXfe6M0ezGWAMwoIjyx4ZBRCVNlSVaqvRIqwZAYYxmOCly1rKbJ17eI1';

// export const fetchAlbums = async (pageId, accessToken) => {
//   try {
//     const response = await facebookAPI.get(`/${pageId}/albums?fields=id,name,cover_photo{source}&access_token=${accessToken}`);
//     return response.data.data;
//   } catch (error) {
//     throw error;
//   }
// };


// export const fetchPhotos = async (albumId, accessToken) => {
//   try {
//     const response = await facebookAPI.get(`/${albumId}/photos?fields=id,source&access_token=${accessToken}`);


//     return response.data.data;
//   } catch (error) {
//     throw error;
//   }
// };

// import axios from 'axios';




export const fetchAlbums = async (pageId) => {
  try {
    const response = await facebookAPI.get(`/${pageId}/albums?fields=id,name,cover_photo{source}&access_token=${accessToken}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPhotos = async (albumId) => {
  try {
    const response = await facebookAPI.get(`/${albumId}/photos?fields=id,source&access_token=${accessToken}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
