import Tabs from "./Tabs";
import Items from "./Items";
import Data from "./PortfolioData";
import { useState } from "react";
import Footer from '../Component/Footer'
import MenuBar from "./MenuBar";
import { Link } from "react-router-dom";


const Porfolio = () => {
    const [data, setData] = useState(Data);
    const categoryData = Data.map((value)=>{
          return value.category
     });
    const tabsData= ["all", ...new Set(categoryData)];
    
    const filterCategory=(category) =>{
        if(category=="all"){
            setData(Data);
            return;
        }
       const filteredData =  Data.filter((value)=>{
           return value.category == category;
       })
       setData(filteredData);
    }
    return(
     <>

    <MenuBar/>

      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Gallery</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>

                            <li className="active">Gallery</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}
        <div className="container">
      <div className="row">
           
            <div className="col-ms-12">
            <Tabs filterCategory={filterCategory} tabsData={tabsData}/>
            <Items data={data} />
            </div>
           
        </div>
           
       </div>
 <Footer/>
       </>
    
    )
}

export default Porfolio
