const PortfolioData =[
    {
        id:1,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_1.jpg",
       
        category:"Janamashtami ",
       
    },


    {
        id:2,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_3.jpg",
       
        category:"Janamashtami ",
       
    },

    {
        id:3,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_4.jpg",
        
        category:"Janamashtami ",
       
    },


    {
        id:4,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_5.jpg",
        
        category:"Janamashtami ",
       
    },

    {
        id:5,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_6.jpg",
        
        category:"Janamashtami ",
       
    },


    {
        id:6,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_7.jpg",
       
        category:"Janamashtami ",
       
    },



    {
        id:7,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_9.jpg",
        
        category:"Janamashtami ",
       
    },




    {
        id:8,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Janamasthmi/janamsthmi_8.jpg",
       
        category:"Janamashtami ",
       
    },

    //  Garb image start here 
    {
        id:9,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/1.jpg",
      
        category:"Garba",
       
    },



    {
        id:10,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/2.jpg",
        
        category:"Garba",
       
    },


  {
        id:11,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/3.jpg",
       
        category:"Garba",
       
    },

    {
        id:12,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/4.jpg",
       
        category:"Garba",
       
    },

    {
        id:13,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/5.jpg",
      
        category:"Garba",
       
    },

    {
        id:14,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/6.jpg",
        
        category:"Garba",
       
    },

    {
        id:15,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Garba%20fundraising/7.jpg",
        
        category:"Garba",
       
    },

//    Garba image end 


    {
        id:16,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/1.jpg",
       
        category:"Civic Holiday ",
       
    },


    {
        id:17,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/2.jpg",
       
        category:"Civic Holiday ",
       
    },


    {
        id:18,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/3.jpg",
        
        category:"Civic Holiday ",
       
    },


    {
        id:19,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/4.jpg",
        
        category:"Civic Holiday ",
       
    },

    {
        id:20,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/5.jpg",
        
        category:"Civic Holiday ",
       
    },

    {
        id:21,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/6.jpg",
       
        category:"Civic Holiday ",
       
    },

    {
        id:22,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Civic%20Holiday%20Long/8.jpg",
       
        category:"Civic Holiday ",
       
    },


    //  Holiday image 
    {
        id:23,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/1.jpg",
       
        category:"Maha Shivratri",
       
    },


    {
        id:24,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/2.jpg",
       
        category:"Maha Shivratri",
       
    },

    {
        id:25,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/3.jpg",
        
        category:"Maha Shivratri",
       
    },

    {
        id:26,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/4.jpg",
       
        category:"Maha Shivratri",
       
    },

    {
        id:27,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/5.jpg",
        
        category:"Maha Shivratri",
       
    },

    {
        id:28,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/6.jpg",
        
        category:"Maha Shivratri",
       
    },

    {
        id:29,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/7.jpg",
      
        category:"Maha Shivratri",
       
    },

    {
        id:30,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Maha%20Shivratri%20Celebration/8.jpg",
        
        category:"Maha Shivratri",
       
    },

    //  Maha Shivaratri image end here
    
    {
        id:31,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/2.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:32,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/3.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:33,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/4.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:34,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/5.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:35,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/6.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:36,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/7.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:37,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/8.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:38,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Annual%20Picnic/9.jpg",
        
        category:"Annual Picnic",
       
    },

    {
        id:39,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/1.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },

    {
        id:40,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/2.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },


    {
        id:41,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/3.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },

    {
        id:42,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/4.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },

    {
        id:43,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/5.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },

    {
        id:44,
        image:"https://stssevastorage.blob.core.windows.net/ykportal/Canada/Gallery/Spring%20Spiritual%20Retreat/6.jpg",
        
        category:"Spring Spiritual Retreat",
       
    },

   

   

   

    




    
    
    
    
]

export default PortfolioData;