import { NavLink } from "react-router-dom";

const TempleActivities = () => {
    return (
        <>

            <section className="join-events">

                <div className="upper-box">
                    <div className="icon-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-care"></span></div>

                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">
                                    <figure className="image wow slideInLeft">
                                        <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-2.jpg" alt="" /></figure>
                                </div>
                            </div>

                            <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="sec-title">
                                        <div className="sub-title"><span className="icon"></span>

                                        </div>
                                        <h2> Join Temple Activities</h2>
                                    </div>
                                    <div className="text-content">
                                        <div className="text ">  The temple have deities of Radha-Krishna and Sita-Ram and is a place of worship that promotes selfless devotion to God. Apart from daily prayers and Artis, the temple aims to have regular satsangs, periodic discourses by preachers of Jagadguru Kripalu Parishat, provide easy access to preacher and priest, literature and devotional media such as books, audio CDs and video DVDs to help individuals and families advance on the spiritual path. The temple will also celebrate major Hindu festivals such as Shivratri, Holi, Ram Naumi, Guru Poornima, Janmashtami, Radhashtami and Divali.
  </div>
                                        <div className="link-box"><NavLink to='/CAD/OurEvent' className="default-link">view all events</NavLink></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--Lower Box--> */}

            </section>


        </>

    )


}

export default TempleActivities;