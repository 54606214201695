import { Link } from 'react-router-dom'
const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (

        <>


            <footer className="main-footer">
                <div className="image-layer" style={{
                    backgroundImage: 'url("https://stssevastorage.blob.core.windows.net/ykwebsite/background/footer-bg1.jpg")'

                }}></div>

                <div className="auto-container">
                    {/* <!--Widgets Section--> */}
                    <div className="widgets-section">
                        <div className="row clearfix">

                            {/* <!--Column--> */}
                            <div className="column big-column col-lg-4 col-md-12 col-sm-12">
                                <div className="footer-widget logo-widget">
                                    <div className="widget-content">
                                        <div className="footer-logo">
                                            <Link to="/">
                                                <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/logo.png" alt="" /></Link>
                                        </div>
                                        <div className="text">Radha Madhav Society is part of the worldwide mission of Jagadguru Kripalu Parishat (JKP), a non-profit organization founded by Jagadguru Shree Kripalu ji Maharaj to provide a deeper understanding of true Hinduism and bring to light the wonderful resources of this eternal religion and its all-encompassing culture.</div>
                                        <ul className="social-links clearfix">
                                            <li><Link to="https://www.facebook.com/yugalkunjcanada" target="_blank"><span className="fab fa-facebook-f"></span></Link></li>
                                            <li><Link to="https://www.youtube.com/@YugalKunjCanada375" target="_blank"><span className="fab fa-youtube"></span></Link></li>
                                            {/* <li><Link to="https://www.yelp.com/biz/yugal-kunj-duluth-2" target="_blank"><span className="fab fa-yelp"></span></Link></li> */}
                                            <li><Link to="https://www.instagram.com/yugalkunj_canada/" target="_blank"><span className="fab fa-instagram"></span></Link></li>
                                            {/* <li><Link to="https://video.search.yahoo.com/search/video;_ylt=AwrC1DGkf51cEi8A_gzQtDMD;_ylu=X3oDMTB0N2Noc21lBGNvbG8DYmYxBHBvcwMxBHZ0aWQDBHNlYwNwaXZz?p=yugal+kunj+duluth+ga&amp;fr2=piv-web&amp;fr=yfp-t-s" target="_blank"><span className="fab fa-yahoo"></span></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <!--Column--> */}
                            <div className="big-column col-lg-8 col-md-12 col-sm-12">
                                <div className="row clearfix">

                                    {/* <!--Column--> */}
                                    <div className="column col-lg-4 col-md-4 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <div className="widget-content">
                                                <h3>Quick Links</h3>
                                                <ul>
                                                    <li><Link to="/CAD/Home">Home</Link></li>
                                                    <li><Link to="/CAD/about">About Us</Link></li>
                                                    <li><Link to="/CAD/AboutDidiJi">About Didi Ji</Link></li>
                                                    <li><Link to="/CAD/VideoLectures">Video Lecture</Link></li>
                                                    {/* <li><Link to="https://ykportal.yugalkunj.org/?ParamCountry=USA">classes</Link></li> */}
                                                    {/* <li><Link to="https://ykportal.yugalkunj.org/?ParamCountry=Canada">Visit Us</Link></li> */}

                                                    <li><Link to="/CAD/OurEvent">Events</Link></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Column--> */}
                                    {/* <!--   <div className="column col-lg-4 col-md-4 col-sm-12">
                          <div className="footer-widget links-widget">
                              <div className="widget-content">
                                  <h3>Our Partners</h3>
                                  <ul>
                                      <li><Link to="#">Donate Kausid</Link></li>
                                      <li><Link to="#">Save Our earth</Link></li>
                                      <li><Link to="#">Water Shortages</Link></li>
                                      <li><Link to="#">Recycle Better</Link></li>
                                      <li><Link to="#">Plant Trees Welfare</Link></li>
                                  </ul>
                              </div>
                          </div>
                      </div> --> */}
                                    {/* <!--Column--> */}
                                    <div className="column col-lg-4 col-md-4 col-sm-12">
                                        <div className="footer-widget links-widget">
                                            <div className="widget-content">
                                                <h3>Useful Links</h3>
                                                <ul>

                                                    <li><Link to="/CAD/WordsOfWisdom">Words of Wisdom</Link></li>
                                                    {/* <!--  <li><Link to="comingsoon.html">Join Our Programs</Link></li> --> */}
                                                    <li><Link to="/CAD/TempleActivities">Temple Activities</Link></li>
                                                    <li><Link to="/CAD/ActivityCalendar">Yugal Kunj Calendar</Link></li>

                                                    <li><Link to="/CAD/OurGallery"> Gallery</Link></li>
                                                    <li><Link to="/CAD/contact">Contact</Link></li>
                                                    <li><Link to="/CAD/PrivacyPolicy">Privacy Policy</Link></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column col-lg-4 col-md-12 col-sm-12">
                                        <div className="footer-widget info-widget">
                                            <div className="widget-content">
                                                <h3>Contact </h3>
                                                <ul className="contact-info">
                                                    <li>375 Aberdeen Ave, Hamilton, ON L8P 2R7, Canada</li>
                                                    <li><Link to="tel:+1 (289) 389-5311"><span className="fa fa-phone-alt"></span> Phone +1 (289) 389-5311</Link></li>
                                                    <li><Link to="mailto:info.ca@yugalkunj.org"><span className="fa fa-envelope-open"></span> info.ca@yugalkunj.org</Link></li>
                                                </ul>
                                                 <br/>
                                                <h5 style={{ color: '#fff' }}>Temple Hours</h5>
                                                <ul className="contact-info">
                                                    <li>Mon - Sun : 7:00 AM - 12:00 PM </li>
                                                    <li>Mon - Sun : 3:00 PM - 8:00 PM </li>
                                                   

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* <!--Column--> */}


                        </div>

                    </div>
                </div>

                {/* <!-- Footer Bottom --> */}
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="inner">
                            {/* <!--Copyright--> */}
                            <div className="copyright">Copyrights (c) {currentYear} <Link to="/Canada/Home"> Yugal kunj </Link> All rights reserved.</div>
                        </div>
                    </div>
                </div>

            </footer>


        </>

    )

}
export default Footer;