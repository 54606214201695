import { NavLink } from 'react-router-dom'
// import React, { useState, useEffect } from 'react';
const ServiceCards = (props) => {
  //   Mobile view  column adjustment
  // const [isMobile, setIsMobile] = useState(false);

  // Detect mobile view on component mount
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
  //   };

  //   handleResize(); // Initial check
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (


    <>




      <div className="cause-block1 col-lg-4 col-md-6 col-sm-12 ">
        <div className="inner-box1 wow fadeInUp animated"  >
          <div className="image-box1">
            <figure className="image1">
              <a href={props.Link} >
                <img className='img_height' src={props.imgsrc} alt="services-photo" />
                </a>
            </figure>

          </div>
          <div className="lower-content1">
            <h3><NavLink to={props.Link}>  {props.title}</NavLink></h3>

            <div className="text1">

              {props.Desc}
              <a href={props.Link} className="readmore"> {props.Readbtn}</a>



            </div>
            <a href={props.RegistrationLink} className="theme-btn1 btn-style-two1" target={props.target}>
              <span className="btn-title1">
                <span className="icon fa fa-sign-in-alt" aria-hidden="true">
                </span> {props.registerbtn} </span>
            </a>
          </div>



        </div>


      </div>

    </>


  )

}

export default ServiceCards;