import MenuBar from "../Component/MenuBar";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";

import { Carousel } from "react-bootstrap";

const DevotionalRetreat = () => {
  return (

    <>

      <MenuBar />
      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

        <div className="auto-container">
          <h1>Devotional Retreat  </h1>
        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/USA/Home">Home</NavLink></li>

              <li className="active">Devotional Retreat</li>
            </ul>
          </div>
        </div>

      </section>
      {/* <!--End Banner Section --> */}

      <div className="container">



        <div className="auto-container">

          <div className="centered">
            <hr />
            <h3> Devotional Retreat </h3>
            <hr />
            <a href="https://ykportal.yugalkunj.org" target="_blank" className="alert alert-primary">
              <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
            </a>
            <hr />
          </div>
        </div>




        <div className="row">




          <div className="col-lg-12">
            <div className="card card-margin">
              <div className="card-header no-border">
                <h5 className="card-title">  Devotional Retreat</h5>

              </div>
              <div className="card-body pt-0">
                <div className="widget-49">


                  <figure className="image">
                    <img src="https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2023.png" alt="" />
                    <a href="https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2023.png" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                  </figure>
                  <br />
                  <div className="widget-49-meeting-action">
                    <a href="https://stssevastorage.blob.core.windows.net/ykportal/Retreat_2023.png" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                  </div>



                  <hr />

                  <div>




                

                  </div>



                </div>
              </div>
            </div>
          </div>




        </div>







        <hr />
         <section className="coming-events">

          <div className="auto-container">
            <div className="sec-title centered">

              <h2>  Devotional Retreat Schedules </h2>



            </div>



            <Carousel variant="dark">

            <Carousel.Item >
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week1.jpg"
                  alt="Second slide"
                />

              </Carousel.Item>

              <Carousel.Item >
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week2.jpg"
                  alt="Second slide"
                />

              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week3.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week4.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>


              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week5.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://yugalkunj.org/images/week6.jpg"
                  alt="Third slide"
                />


              </Carousel.Item>



            </Carousel>



          </div>
        </section> 
















      </div>















































      <Footer />
    </>
  )

}
export default DevotionalRetreat;