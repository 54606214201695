import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import SelectCountry from './Country/SelectCountry';
import NotFound from './NotFound';
import Home from './USA/Component/Home';
import AboutPage from './USA/AboutPage';
import Contact from './USA/Contact';
import OurGallery from './USA/OurGallery';
import AboutDidiJi from './USA/AboutDidiJi';
import VideoLectures from './USA/VideoLectures';
import WordsOfWisdom from './USA/WordsOfWisdom';
import TempleActivities from './USA/TempleActivities';
import ActivityCalendar from './USA/ActivityCalendar';
import VolunteerRegistration from './USA/VolunteerRegistration';
import Directions from './USA/Directions';
import EventRentals from './USA/EventRentals';
import OurEvent from './USA/OurEvent';
import CandaHome from './Canada/Component/Home';
import MyGallery from './USA/MyGallery';
import Blogs from './USA/Blogs';
import BlogDetails from './USA/BlogDetails';
import Camp from './USA/Camp/SummerCamp';
import DevotionalRetreat from './USA/Camp/DevotionalRetreat';
import Volleyball from './USA/Camp/Volleyball';
import MilwaukeeSummerCamp from './USA/Camp/MilwaukeeSummerCamp';
import SpringBreakKidsCamp from './USA/Camp/Springbreakkidscamp';
import GlobalSeva from './USA/Camp/GlobalSeva';
import Winter from './USA/Camp/WinterCamp';
import FacebookAlbum from './Canada/Component/AlbumList';
import CanadaAbout from './Canada/AboutPage';
import CanadaAboutDidiJi from './Canada/AboutDidiJi';
import CanadaVideoLectures from './Canada/VideoLectures';
import CanadaContact from './Canada/Contact';
import CanadaDirection from './Canada/Directions';
import CanadaActivityCalendar from './Canada/ActivityCalendar';
import CanadaEventRentals from './Canada/EventRentals';
import CanadaOurEvent from './Canada/OurEvent';
import CanadaOurGallery from './Canada/OurGallery';
import CanadaOurServices from './Canada/OurServices';
import CanadaVolunteerRegistration from './Canada/VolunteerRegistration';
import CanadaWordsOfWisdom from './Canada/WordsOfWisdom';
import CanadaTempleActivities from './Canada/TempleActivies';
import CanadaClass from './Canada/Class/Index';
import CanadaSummerCamp from './Canada/Camps/SummerCamp';
import CanadaGallery from './Canada/Component/Porfolio';
import CanadaRetreat from './Canada/Camps/Retreat';
import CanadaAllEvent from './Canada/Component/AllEvent';
import CanadaHamiltoncamp from './Canada/Camps/Summer-camp-2024';
import ClevelandRetreat from './USA/Camp/ClevelandOhioRetreat';
import FamilyOvernightCamping from './USA/Camp/Overnightcamp';
import CivicHolidayRetreat from './Canada/Camps/CivicHolidayRetreat';
import YugalKunjexpansion from "./USA/YugalKunjexpansion";
import CADYugalKunjexpansion from  "./Canada/YugalKunjexpansion"
import PrivacyPolicy from './Canada/Component/PrivacyPolicy';
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path='/' element={<SelectCountry />} />
          <Route path='/USA/Home' element={<Home />} />
          <Route path='/USA/about' element={<AboutPage />} />
          <Route path='/USA/ourGallery' element={<OurGallery />} />
          <Route path='/USA/gallery' element={<OurGallery />} />
          <Route path='/USA/aboutDidiJi' element={<AboutDidiJi />} />
          <Route path='/USA/contact' element={<Contact />} />
          <Route path='/USA/videoLectures' element={<VideoLectures />} />
          <Route path='/USA/wordsofwisdom' element={<WordsOfWisdom />} />
          <Route path='/USA/templeactivities' element={<TempleActivities />} />
          <Route path='/USA/activitycalendar' element={<ActivityCalendar />} />
          <Route path='/USA/VolunteerRegistration' element={<VolunteerRegistration />} />
          <Route path='/USA/directions' element={<Directions />} />
          <Route path='/USA/eventrentals' element={<EventRentals />} />
          <Route path='/USA/upcomingevents' element={<OurEvent />} />
          <Route path='/USA/Blogs' element={<Blogs />} />
          <Route path='/USA/BlogDetails' element={<BlogDetails />} />
          <Route path='/USA/Camp/SummerCamp' element={<Camp />} />
          <Route path='/USA/Camp/DevotionalRetreat' element={<DevotionalRetreat />} />
          <Route path='/USA/Camp/Winter' element={<Winter />} />
          <Route path='/USA/Camp/MilwaukeeSummerCamp' element={<MilwaukeeSummerCamp />} />
          <Route path='/USA/Camp/Springbreakkidscamp' element={<SpringBreakKidsCamp />} />
          <Route path='/USA/Camp/GlobalSeva' element={<GlobalSeva />} />
          <Route path='/USA/Camp/Volleyball' element={<Volleyball />} />
          <Route path='/USA/Camp/Cleveland-Retreat' element={<ClevelandRetreat />} />
          <Route path='/USA/Camp/FamilyOvernightCamping' element={<FamilyOvernightCamping />} />
          <Route path='/USA/YugalKunjexpansion' element={<YugalKunjexpansion />} />
          <Route path='/MyGallery' element={<MyGallery />} />
          <Route path='/CAD/Home' element={<CandaHome />} />
          <Route path='/CAD/about' element={<CanadaAbout />} />
          <Route path='/CAD/AboutDidiJi' element={<CanadaAboutDidiJi />} />
          <Route path='/CAD/VideoLectures' element={<CanadaVideoLectures />} />
          <Route path='/CAD/WordsOfWisdom' element={<CanadaWordsOfWisdom />} />
          <Route path='/CAD/Contact' element={<CanadaContact />} />
          <Route path='/CAD/Direction' element={<CanadaDirection />} />
          <Route path='/CAD/ActivityCalendar' element={<CanadaActivityCalendar />} />
          <Route path='/CAD/EventRentals' element={<CanadaEventRentals />} />
          <Route path='/CAD/OurEvent' element={<CanadaOurEvent />} />
          <Route path='/CAD/OurGallery' element={<CanadaOurGallery />} />
          <Route path='/CAD/OurServices' element={<CanadaOurServices />} />
          <Route path='/CAD/VolunteerRegistration' element={<CanadaVolunteerRegistration />} />
          <Route path='/CAD/TempleActivities' element={<CanadaTempleActivities />} />
          <Route path='/CAD/Gallery' element={<CanadaGallery />} />
          <Route path='/Cad/AllEvent' element={<CanadaAllEvent />} />
          <Route path='/Canada/Camps/SummerCamp' element={<CanadaSummerCamp />} />
          <Route path='/Cad/Class' element={<CanadaClass />} />
          <Route path='/Canada/Component/AlbumList' element={<FacebookAlbum />} />
          <Route path='/CAD/Camps/Retreat' element={<CanadaRetreat />} />
          <Route path='/Summer-camp-2024' element={<CanadaHamiltoncamp />} />
          <Route path='/CAD/PrivacyPolicy' element={<PrivacyPolicy />} />

          <Route path='/CAD/Camps/CivicHolidayRetreat' element={<CivicHolidayRetreat />} />
          <Route path='/CAD/YugalKunjexpansion' element={<CADYugalKunjexpansion />} />




        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
