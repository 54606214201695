import Headerstyle from '../asstes/css/flexbox.module.css'

import { Link } from 'react-router-dom';
const Header = () => {

    return (

        <>


            <header className="main-header header-style-one">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light row ">
                        <div className="col-md-6">
                            <Link className="navbar-brand" to="/">
                                <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/logo.png" style={{ height: '50px' }} alt="Yk Logo" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse col-md-3 offset-3 text-right" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a className="nav-link" href="#">  &nbsp; &nbsp; <span className="sr-only">(current)</span></a>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        Country/Region <span className="flaticon-down-arrow "></span>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <Link className="dropdown-item" to='/USA/Home' >USA</Link>
                                        <Link className="dropdown-item" to='/CAD/Home'>Canada</Link>

                                    </div>
                                </li>
                            </ul>
                        </div>

                    </nav>
                </div>

            </header>






        </>




    )


}

export default Header;