

const About = () => {
    return (
        <>

            {/* <!--About Section--> */}
            <section className="about-section-four">

                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <!--Text Column--> */}
                        <div className="text-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="sec-title">
                                    <div className="sub-title"><span className="icon"></span> </div>
                                    <h2 id="heading_border">Welcome To Yugal Kunj </h2>
                                </div>
                                <div className="text">
                                    Radha Madhav Society is part of the worldwide mission of Jagadguru Kripalu Parishat (JKP), a non-profit organization founded by Jagadguru Shree Kripalu ji Maharaj to provide a deeper understanding of true Hinduism and bring to light the wonderful resources of this eternal religion and its all-encompassing culture.
                                    The temple is an initiative of Sushri Siddheshvari Devi (Didi Ji), a senior disciple of Shri Jagadguru Shri Kripalu Ji Maharaj, who is well-known to the local Atlanta population as well as a respected figure in the US, Canada, Trinidad and other parts of the world.
                                </div>
                                <div className="link-box"><a href="about.html" className="default-link">Read More</a></div>


                                <div className="link-box">
                                    <p> Temple Hours</p>

                                    <ul className="time_list">
                                        <li><a style={{ color: '#333' }}><span className="icon fa fa-clock"></span> Mon - Fri : 7:00 AM - 8:00 AM,  &nbsp;  Mon - Fri : 5:00 PM - 8:00 PM</a></li>

                                        <li><a style={{ color: '#333' }}><span className="icon fa fa-clock"></span> Sat - Sun : 7:00 AM - 8:00 PM</a></li>

                                    </ul>




                                </div>

                            </div>
                        </div>
                        {/* <!--Image Column--> */}
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image-box">
                                    <figure className="image">
                                        <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-6.jpg" alt="" /></figure>
                                    <div className="caption-box wow fadeInUp animated" data-wow-delay="0ms" >
                                        <div className="cap-inner">
                                            <h3>#Change Your Thoughts, Change Your Life</h3>
                                            <div className="text">"Inspiring selfless devotion to God as taught by the timeless Vedas and Hindu Scriptures and helping community members of all ages to lead a happy, healthy and spiritually oriented life."</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>


    )


}

export default About;