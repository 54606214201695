import React from 'react'
import MenuBar from '../Component/MenuBar'
import Footer from '../Component/Footer'
import { NavLink } from "react-router-dom";
const Vollyball = () => {
    return (
        <>

            <MenuBar />

            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Volleyball Tournament  </h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to="/USA/Home">Home</NavLink></li>

                            <li className="active"> Volleyball Tournament</li>
                        </ul>
                    </div>
                </div>

            </section>


            <div className="container">



                <div className="auto-container">

                    <div className="centered">
                        <hr />
                        <h3> Volleyball Tournament </h3>
                        <hr />
                         <a href="https://ykportal.yugalkunj.org" target="_blank" className="alert alert-primary">
                            <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
                        </a> 
                        <hr />
                    </div>
                </div>




                <div className="row">




                    <div className="col-lg-12">
                        <div className="card card-margin" style={{ marginBottom: '20px;' }}>
                            <div className="card-header no-border">
                                <h5 className="card-title">  Volleyball Tournament</h5>

                            </div>
                            <div className="card-body pt-0">
                                <div className="widget-49">


                                    <figure className="image">
                                        <img src="https://yugalkunj.org/images/vollleyballflyer.png" width='100%' alt="" />
                                        <a href="https://yugalkunj.org/images/vollleyballflyer.png" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                                    </figure>
                                    <br />
                                    <div className="widget-49-meeting-action">
                                        <a href="https://yugalkunj.org/images/vollleyballflyer.png" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                                    </div>



                                    <hr />

                                    <div className='camp_info'>

                                        <ul>



                                            <li>Tournament will run from October 13th to October 22nd</li>
                                            <li>Matches will be played on Fridays, Saturdays, and Sundays</li>
                                            <li>Attire: T-Shirt and shorts appropriate for the temple</li>
                                            <li>Team registration fee: $51</li>
                                            <li>Registration Information:
                                                <ul>
                                                    <li> Team Name</li>
                                                    <li> Phone Number & Email Address</li>
                                                    <li> All team member names</li>
                                                    <li> Any preferred match timings</li>

                                                </ul>

                                            </li>

                                            <li> Tournament Rules:
                                                <ul>
                                                    <li> Bracket-style</li>
                                                    <li> Teams can have up to 10 members</li>
                                                    <li> Only 6 people will be on the court at a time</li>
                                                    <li> Each match will be 3 sets to 25 points, win by 2 points </li>


                                                    <li> A bracket will be created and shared closer to Oct 13 once teams have registered</li>

                                                </ul>


                                            </li>






                                        </ul>




                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />




                </div>
























            </div>
            <br />

            <Footer />
        </>
    )
}

export default Vollyball
