import Carousel from 'react-bootstrap/Carousel';

function Slider() {
  return (

    <section className="coming-events">

      <div className="auto-container">
        <div className="sec-title centered">
          <div className="upper-icon"></div>
          <h2> Upcoming Events </h2>



        </div>



        <Carousel variant="dark">

          <Carousel.Item >
            <img
              className="d-block w-100 banner_height"
              src="https://stssevastorage.blob.core.windows.net/ykportal/Canada/Winter%20Retreat.jpeg"
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Winter Family Retreat</h3>

            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item >
            <img
              className="d-block w-100 banner_height"
              src="https://stssevastorage.blob.core.windows.net/ykportal/Canada/Winter%20Retreat.jpeg"
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3>Winter Family Retreat</h3>

            </Carousel.Caption>
          </Carousel.Item>

        </Carousel>

      </div>
    </section>




  );
}

export default Slider;