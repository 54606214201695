const TempleName = () => {
    return (
        <>
            {/* <!--Parallax Section--> */}
            <section className="parallax-section">
                <div className="image-layer" style={{ backgroundImage: "url(https://stssevastorage.blob.core.windows.net/ykwebsite/background/parallax-bg-3.jpg" }}></div>

                <div className="auto-container">
                    <div className="content">
                        <a href="#" className="play-box"><span className="icon fa fa-play"><i className="ripple"></i></span></a>
                        <h2>
                            Yugal Kunj Name Given By  <span className="
                    txt-yellow">Jagadguru Shri Kripalu Ji Maharaj.</span>
                            {/* <!-- . Make Your Money Count To --> 
                    <span className="txt-purple"> 
                        <!-- Save Them! --></span> */}
                        </h2>
                        <div className="text">
                            Inspiring selfless devotion to God as taught by the timeless Vedas and Hindu Scriptures and helping community members of all ages to lead a happy, healthy and spiritually oriented life.

                        </div>
                    </div>
                </div>
            </section>


        </>

    )



}

export default TempleName;