

import { NavLink } from 'react-router-dom';

const WhatWeDo = (props) => {
    return (
        <>
            <div className="service-block col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay="0ms">
                <div className="inner-box">
                    <div className="icon">
                        <span className={props.imgicon}></span>
                    </div>
                    <h3><a href={props.Link}>{props.title}</a></h3>
                    <div className="text">
                        {props.Desc}

                    </div>
                    <div className="link-box"><NavLink to={props.Link} className="theme-btn" >{props.Readtext}</NavLink></div>
                </div>
            </div>


            {/* <!--Service Block--> */}

            {/* <div className="service-block col-lg-4 col-md-6 col-sm-12 wow fadeInLeft animated" data-wow-delay="0ms" >
                        <div className="inner-box">
                            <div className="icon"><span className="flaticon-therapy"></span></div>
                            <h3><a href="words-of-wisdom.html">Words of Wisdom</a></h3>
                            <div className="text">
                                Words of Wisdom is inspirational message sent out by Didi Ji every Friday. To receive weekly emails of Words of Wisdom

                            </div>
                            <div className="link-box"><a href="words-of-wisdom.html" className="theme-btn">Read More</a></div>
                        </div>
                    </div> */}

        </>

    )

}

export default WhatWeDo;