import EventCard from "./EventCard";
import EventCardData from './EventCardData';
const Events = () =>{
  return (

  <>
  
  {/* <!--Causes Section--> */}
        <section className="causes-section alternate">
            {/* <!-- <div className="icon-one paroller" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-heart-2"></span></div> --> */}

            <div className="icon-two paroller" data-paroller-factor="0.20" data-paroller-factor-lg="0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-donation"></span></div>

            <div className="icon-three paroller" data-paroller-factor="-0.10" data-paroller-factor-lg="-0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-shelter"></span></div>

            <div className="icon-four paroller" data-paroller-factor="-0.10" data-paroller-factor-lg="-0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-money-jar"></span></div>

            <div className="icon-five paroller" data-paroller-factor="-0.10" data-paroller-factor-lg="-0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-dove-2"></span></div>

            <div className="icon-six paroller" data-paroller-factor="0.10" data-paroller-factor-lg="0.15" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="horizontal"><span className="flaticon-gift-box"></span></div>

            {/* <!--  <div className="icon-seven paroller" data-paroller-factor="0.30" data-paroller-factor-lg="0.30" data-paroller-factor-sm="0.10" data-paroller-type="foreground" data-paroller-direction="vertical"><span className="flaticon-heart-2"></span></div> --> */}

            <div className="auto-container">

                <div className="sec-title centered">
                    <div className="upper-icon"></div>
                    <h2>Our Events </h2>
                    {/* <!-- <div className="text">We believe what we achieve</div> --> */}
                </div>

                <div className="row clearfix">

                    {/* <!--Cause Block--> */}
                    {/* <EventCard 
                     imgsrc="https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/event/recent-event1.jpg"
                     Link="#"
                     title="Life Story Of Shri Maharaji In His Own Words"
                     Desc="The first spiritual conference I organized was in Chitrakoot. No conference of the kind had ever been organized before, and it lasted 16 days. Many arguments took place during it. Eventually I had to speak on the stage. There was a speaker by the name Pathik Ji Maharaj; he challenged me. I had ........"
                    /> */}

                 {EventCardData.map( (val, index)=> {
                     return(
                        <EventCard 
                        imgsrc={val.imgsrc}
                        Link={val.Link}
                        title={val.title}
                        Desc={val.Desc}
                       />
   

                     )
                 }



                 ) 

                 
                 }

                </div>

            </div>
        </section>

  
  </>

  )

}

export default Events;