import MenuBar from "./Component/MenuBar";
import Footer from "./Component/Footer";
import { Link } from 'react-router-dom'
import BlogsCard from "./Component/BlogsCard";
import BlogsData from "./Component/BlogsData";
const BlogDetails = () => {
    return (
        <>
            <MenuBar />
            <section className="page-banner">
                <div className="image-layer" style={{ backgroundImage: 'url(https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-page-title-2.jpg)' }}></div>

                <div className="auto-container">
                    <h1>Blog Details</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/USA/Home'>Home</Link></li>
                            <li><Link to='/USA/Blogs'>Blogs</Link></li>

                            <li className="active"> BLOG Details</li>
                        </ul>
                    </div>
                </div>

            </section>

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="event-detail-image">
                        <figure className="image">
                            <img src="https://radhamadhavsociety.org/images/blog/2019/Canmore-Retreat-1.jpg" alt="" />

                        </figure>

                    </div>

                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Event Details--> */}
                                <div className="event-details">
                                    <div className="lower-box">
                                        <h2>Canmore Retreat</h2>

                                        <div className="post-content">

                                            <p>
                                                Devotees from various parts of Canada and US gathered for a retreat near Banff, Canada. Guided by Sushri Siddheshvari Devi Ji, this third annual retreat was held at Copperstone Resort at the base of Rocky Mountains. The natural beauty of the surroundings perfectly complimented the beauty of the retreat’s devotional atmosphere, and the tall and majestic mountains silently inspired all to reach the heights of devotion
                                            </p>





                                        </div>

                                        <div className="info-box clearfix">
                                            <div className="cause-info">
                                                <ul className="clearfix">
                                                    <li><span className="far fa-clock"></span> August 31 - Starts 5.00 PM - Ends 9:00 PM</li>
                                                    {/* <!--<li><span className="fa fa-map-marker-alt"></span> Tottenham, London N17 6HW</li>--> */}
                                                </ul>
                                            </div>
                                            {/* <!--<div className="clearfix">
                                            
                                            <div className="share-post clearfix">
                                                <div className="share-title">Share This</div>
                                                <ul className="social-links clearfix">
                                                    <li><a href="#"><span className="fab fa-twitter"></span></a></li>
                                                    <li><a href="#"><span className="fab fa-facebook-f"></span></a></li>
                                                    <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                                    <li><a href="#"><span className="fab fa-youtube"></span></a></li>
                                                </ul>
                                            </div>
                                        </div>--> */}

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/* <!--End Content Side--> */}

                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}













            <Footer />



        </>


    )

}


export default BlogDetails;