import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";

import { NavLink } from "react-router-dom";

const VolunteerRegistration = () => {
    return (
        <>

            {/* <Header/> */}

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>Volunteer Registration</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">Volunteer Registration</li>
                        </ul>
                    </div>
                </div>

            </section >
            {/* <!--End Banner Section --> */}

            {/* <!--Contact Section--> */}
            <section className="contact-section contact-page">

                <div className="auto-container">

                    <div className="sec-title centered">
                        <div className="upper-icon"></div>
                        <h2>Volunteer Registration</h2>
                        {/* <!-- <div className="text">Join Us To Save The World Of Humanitarian And Feel Happy To Support</div> --> */}
                    </div>



                    {/* <!--Image Column---> */}
                    <div className=" col-xl-12 col-lg-12 col-md-8 col-sm-12 volunteer-info">

                        {/* <!--Info Block--> */}
                        <div className="info-block bg-blue">
                            <div className="inner">

                                <div className="row clearfix">
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <h2>Yugal Kunj</h2>
                                        <h5>Radha Madhav Society</h5>
                                        <hr />
                                        <ul className="info">
                                            <li><span className="fa fa-map-marker-alt"></span> 2769 Duluth Hwy Duluth, GA 30096</li>

                                            <li><span className="fa fa-globe"></span> <a href="https://yugalkunj.org/">www.yugalkunj.org</a></li>

                                            {/* <!-- <li><span className="fa fa-globe"></span> <a href="https://www.radhamadhavsociety.org/">www.radhamadhavsociety.org</a></li>---> */}
                                            <li><span className="fa fa-phone-alt"></span> <a href="tel:812-070-3692">Phone 678 920 6669</a></li>
                                            <li><span className="fa fa-envelope-open"></span> <a href="mailto:info@yugalkunj.org">info@yugalkunj.org</a></li>
                                        </ul>



                                        <ul className="social-links clearfix" style={{ marginBottom: '30px' }}>

                                            <li><a href="https://www.facebook.com/yugalkunj/" target="_blank"><span className="fab fa-facebook-f"></span></a></li>
                                            <li><a href="https://twitter.com/search?q=yugalkunj" target="_blank"><span className="fab fa-twitter"></span></a></li>
                                            <li><a href="https://www.yelp.com/biz/yugal-kunj-duluth-2" target="_blank"><span className="fab fa-yelp"></span></a></li>
                                            <li><a href="https://www.instagram.com/yugal_kunj/" target="_blank"><span className="fab fa-instagram"></span></a></li>
                                            <li><a href="https://video.search.yahoo.com/search/video;_ylt=AwrC1DGkf51cEi8A_gzQtDMD;_ylu=X3oDMTB0N2Noc21lBGNvbG8DYmYxBHBvcwMxBHZ0aWQDBHNlYwNwaXZz?p=yugal+kunj+duluth+ga&amp;fr2=piv-web&amp;fr=yfp-t-s" target="_blank"><span className="fab fa-yahoo"></span></a></li>


                                        </ul>

                                    </div>
                                    <br />
                                    <div className="col-lg-4 col-md-4 col-sm-12">

                                        <a href="https://ykportal.yugalkunj.org/home/volunteeryk" target="_blank" className="theme-btn btn-style-one"><span className="btn-title"> Please Click Here</span></a>


                                    </div>



                                </div>


                            </div>
                        </div>


                    </div>

                </div>


            </section>


<br/>



            <Footer />






        </>


    )


}

export default VolunteerRegistration;