import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
// import {useDispatch} from 'react-redux'
import USAimage from '../asstes/images/USA_atlanta_milwaukee.png';
import CanadaImg from '../asstes/images/Canada_himilton.png';
import '../asstes/css/SelectCountryStyle.scss';
import flexboxstyle from '../asstes/css/flexbox.module.css';
import Footer from './Footer';
// import {selectedCountry} from '../State/CountrySlice'

const SelectCountry = () => {

  // const dispatch = useDispatch();
  const navigate = useNavigate(); // Access the navigation function

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [radioButtonsVisible, setRadioButtonsVisible] = useState(true);

  const handleCountryChange = (event) => {


    setSelectedCountry(event.target.value);
    setRadioButtonsVisible(false);
  };

  // Handle navigation to the selected country page
  const navigateToCountryPage = () => {
    if (selectedCountry === 'usa') {
      navigate('/USA/Home');
    } else if (selectedCountry === 'canada') {
      navigate('/Cad/Home');
    }
  };

  return (
    <>
      {radioButtonsVisible && (
        <div>
          <Header />
          <section className="causes-section1">
            <div className="auto-container">
              <div className="sec-title centered">
                {/* <div className="upper-icon1"></div> */}
                <h2>Country/Region</h2>
                <p>Please select Country/Region</p>
              </div>

              <div className={flexboxstyle.flexbox}>
                <div>
                  <label className="services-box-item">
                    <input
                      type="radio"
                      name="country"
                      value="usa"
                      checked={selectedCountry === 'usa'}
                      onChange={handleCountryChange}
                    />
                    <span className="checkmark"></span>
                    <span className="w-service-box text-center position-relative">
                      <span className="tooltip-info" title="USA Center: Atlanta and Milwaukee"></span>
                      <span className="service-icon">
                        <img src={USAimage} alt="" className="map_height" />
                      </span>
                      <span className="service-text">USA</span>
                    </span>
                  </label>
                </div>
                <div>
                  <label className="services-box-item">
                    <input
                      type="radio"
                      name="country"
                      value="canada"
                      checked={selectedCountry === 'canada'}
                      onChange={handleCountryChange}
                    />
                    <span className="w-service-box text-center position-relative">
                      <span className="tooltip-info" data-toggle="tooltip" data-placement="top" title="USA Center: Atlanta and Milwaukee"></span>
                      <span className="service-icon">
                        <img src={CanadaImg} alt="" className="map_height" />
                      </span>
                      <span className="service-text">Canada</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </section>
          <Footer />


        </div>
      )}

      {selectedCountry && navigateToCountryPage()} {/* Trigger navigation to the selected country page */}

      {!selectedCountry && (
        <div>
          {/* <p>Please select a country.</p> */}
        </div>
      )}
    </>
  );
};

export default SelectCountry;