import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
const Directions = () => {
    return (
        <>
       

       <Helmet>
        <title> Yugalkunj Canada Hamilton </title>
          <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
          <meta name="keywords" content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
          <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

            <MenuBar />

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Directions</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to='/CAD/Home'>Home</Link></li>

                            <li className="active"> Directions</li>
                        </ul>
                    </div>
                </div>

            </section>



            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">


                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Directions</h4>
                                </div>




                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="inner_heading_title orange-border" >
                                            <h4>DRIVING DIRECTION TO YUGAL KUNJ FROM THE CITY OF TORONTO</h4>

                                            <div className="text">

                                                <ul>
                                                    <li> 1)	Continue on HWY-403 (QEW). Go for 20.0 mi.</li>
                                                    <li> 2)	Take exit 70 toward Main St. onto HWY-2/HWY-8 E (Main St W). Go for 0.6 mi.</li>
                                                    <li> 3)	Turn right onto Dundurn St S. Go for 0.6 mi.</li>
                                                    <li> 4)	Turn left onto Aberdeen Ave. Go for 0.2 mi.</li>
                                                    <li> 5)	375 Aberdeen Ave</li>


                                                </ul>
                                                {/* <!-- <p>At airport: On arrival at the gate – take the “plane train” towards baggage claim area. Baggage claim is the last stop on the train.</p> --> */}

                                                {/* <!-- <p className="lead">
                                                You have 3 options to travel to Yugal Kunj temple
                                            </p> --> */}

                                            </div>

                                        </div>


                                        <div className="col-md-12">
                                            <div className="map">

                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.065578691638!2d-79.89234328539278!3d43.25004738642558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b6c61cff5eb%3A0xec2d7e761feaf66f!2s375%20Aberdeen%20Ave%2C%20Hamilton%2C%20ON%20L8P%202R7%2C%20Canada!5e0!3m2!1sen!2sin!4v1679310012445!5m2!1sen!2sin" width="100%" height="250" ></iframe>
                                            </div>

                                        </div>










                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>



            <Footer />

        </>


    )


}

export default Directions;