import Carousel from 'react-bootstrap/Carousel';

function Slider() {




  return (

    <section className="coming-events">

      <div className="auto-container">
        <div className="sec-title centered">
          <div className="upper-icon"></div>
          <h2> Spring Break Kids Camp Shedule and Food Menu </h2>

        </div>

        <Carousel variant="dark">


          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images/Spring%20Break%20Camp%202024%20-%20Schedule%20%20.jpg"
              alt="Third slide"
            />
          
          </Carousel.Item>


          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://yugalkunj.org/images/SpringBreak_Camp_Menu.png"
              alt="Third slide"
            />
          
          </Carousel.Item>

       

        </Carousel>

      </div>
    </section>




  );
}

export default Slider;