// import Header from '../Component/Header';
import About from './About';
// import Gallery from './Gallery';
import Services from './Services';
import Footer from './Footer';
// import Testimonials from './Testimonials';
import TempleName from './TempleName';
import TempleActivities from './TempleActivities'
// import Event from './Event';
import WhatWeDo from './WhatWeDo';
// import LeaveAMessage from './LeaveAMessage';
import Slider from './Slider';
import MenuBar from './MenuBar';
import FacebookGallery from './FacebookGallery';
import SidebarMenu from './SidebarMenu';



const Home = () => {
    return (
        <>
            <div className="page-wrapper">


                {/* <!-- Main Header --> */}
                {/* <Header/> */}


                <MenuBar />


                {/* <!-- End Main Header --> */}
                {/* <SidebarMenu/>s */}



                {/* <!-- new Section start here --> */}

                <Services />

                {/* <!--About Section--> */}
                <About />


                <WhatWeDo />


                {/*  Slider */}
                {/* <Slider /> */}



                {/*  Gallerry Section start here */}


                {/* <Gallery /> */}
                <FacebookGallery/>
              
            

                {/*  Temple description  */}

                <TempleName />


                {/* <Event /> */}

                <TempleActivities />

                {/* <Testimonials /> */}

                {/* <LeaveAMessage /> */}

                {/* <!-- Main Footer --> */}

                <section className="call-to-action">
                    <div className="image-layer" style={{ backgroundImage: "url('https://sevastorage.blob.core.windows.net/seva-blob/Yugalkunj_website/background/bg-pattern-1.png')" }}></div>

                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="title-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <h2 className='text-center'>Become a vital part of Yugal Kunj &amp; make a real difference to lives of many!</h2>
                            </div>
                            <div className="link-column col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                <div className="link-box">
                                    {/* <a href="#" className="theme-btn btn-style-one"><span className="btn-title">join to Volunteer</span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />


            </div>




        </>
    );
}

export default Home;
