import Footer from "./Component/Footer";
// import Header from "./Component/Header";
import MenuBar from "./Component/MenuBar";
import { NavLink } from "react-router-dom";

const TempleActivities = () => {
    return (
        <>
            {/* <Header/> */}

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer"></div>

                <div className="auto-container">
                    <h1>Temple Activities</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><NavLink to='/USA/Home'>Home</NavLink></li>
                            <li className="active">Temple Activities</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}
            {/* <!--FAQs Section--> */}
            <section className="faq-section">
                <div className="auto-container">

                    <div className="row clearfix">

                        <div className="col-md-12">



                            <div className="panel panel-primary">
                                <div className="panel panel-heading">

                                    <h4>Temple Activities</h4>
                                </div>


                                <div className="panel panel-body">
                                    <div className="inner-heading">



                                        <div className="text">


                                            The temple houses the beautiful deities of of Radha Krishna, Sita Ram and our Guru Jagadguru Shri Kripalu Ji Maharaj.  Selfless devotion to God and Guru is taught and practiced here.
                                            Aarti and satsang form the basis of the daily practice of devotion. Discourses by Siddheshvari Didi Ji and visiting preachers of Jagadguru Kripalu Parishat, along with easy access to devotional literature, audio CDs and video DVDs help individuals and families advance on the spiritual path.
                                            Major Hindu festivals such as Shivratri, Holi, Ram Naumi, Guru Poornima, Janmashtami, Radhashtami and Divali are celebrated here with great enthusiasm and devotion.


                                        </div>



                                        <div className="inner_heading_title orange-border" style={{ marginTop: '10px' }}>
                                            <h4> Weekly Satsangs </h4>

                                            <div className="text">

                                                <p className="lead">
                                                    Weekly satsangs are held every Sunday from 10AM to 12 Noon. The typical satsang schedule is as follows:
                                                </p>

                                                <ul className="satsang_list">

                                                    <li>Prayer</li>
                                                    <li> Bhajans</li>
                                                    <li>Video discourse by Jagadguru Shri Kripalu Ji Maharaj</li>
                                                    <li>Parallel classes for children</li>
                                                    <li>Aarti</li>
                                                    <li>Lunch Prasad</li>


                                                </ul>
                                            </div>

                                        </div>


                                        <div className="inner_heading_title purple-border">
                                            <h4> Sunday School </h4>

                                            <div className="text">

                                                <p className="lead">The Following educational and cultural classes are offered:</p>

                                                <ul className="satsang_list">

                                                    <li>Hinduism</li>
                                                    <li>Dholak</li>
                                                    <li>Harmonium</li>
                                                    <li>Dance</li>
                                                    <li>Singing</li>
                                                    <li>Indian languages such as Hindi</li>
                                                    <li>Leadership Workshop</li>


                                                </ul>
                                            </div>

                                        </div>



                                    </div>



                                </div>



                            </div>

                        </div>


                    </div>


                </div>
            </section>


            <Footer />

        </>


    )


}

export default TempleActivities;