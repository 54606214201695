import MenuBar from './MenuBar'
import Footer from './Footer'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlbums, getAlbumPhotos }  from './../../actions/album';
import { Link } from 'react-router-dom';




const AlbumList = () => {


  const dispatch = useDispatch();
  const albums = useSelector((state) => state.album.albums);
  const photos = useSelector((state) => state.album.photos);

  // Local state to track the active album and its photos
  const [activeAlbum, setActiveAlbum] = useState(null);

  useEffect(() => {
    dispatch(getAlbums());
  }, [dispatch]);

  useEffect(() => {
    // Set the default active album and load its photos
    if (albums && albums.length > 0 && !activeAlbum) {
      const defaultAlbum = albums[0];
      setActiveAlbum(defaultAlbum.id);
      dispatch(getAlbumPhotos(defaultAlbum.id));
    }
  }, [albums, activeAlbum, dispatch]);

  const handleDivClick = (id) => {
    setActiveAlbum(id);
    dispatch(getAlbumPhotos(id));
  };

  return (


    


    <>
 <MenuBar/>

  {/* <!-- Page Banner Section --> */}
  <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Our Gallery</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>

                            <li className="active">Our Gallery</li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

 <section className="activity-section">
 <div className="auto-container">

   
<div className="album-container">
<ul className='album_list'>
{/* {albums &&
        albums.slice(0,5).map((album) => (
         
              <li key={album.id} onClick={() => handleDivClick(album.id)}>
                <a href='#'>{album.name}</a>
              </li>
            
         
        ))} */}


{albums &&
  albums.slice(0, Math.max(albums.length - 2, 0)).map((album) => (
    <li key={album.id} onClick={() => handleDivClick(album.id)}>
      <a href='#'>{album.name}</a>
    </li>
  ))}

</ul>

</div>

     

      {photos?.length > 0 ? (
        <div className='row'>
          {photos.map((photo) => (
            <div className='col-md-4' >
             <div className='album_height'>
              <img key={photo.id} src={photo.source} alt={`Photo ${photo.id}`} />
             </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No photos available</p>
      )}
      </div>
</section>
<Footer/>

    </>
  );
};

export default AlbumList;

