import MenuBar from "../Component/MenuBar";
import Footer from "../Component/Footer";
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";

const Summer_Camp_Hamilton = () => {

  console.log('Rendering Helmet component');
  return (
    <>

<Helmet>
      <title>Summer camp 2024</title>
      <meta name="description" content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages." />
      <meta name="keywords" content="Summer camp 2024, Summer Camp Hamilton 2024, Summer camp summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education" />
      <meta name="author" content="Summer camp 2024" />
      <link rel="canonical" href="https://yugalkunj.org" />

      <meta name="title" content="Summer camp 2024"/>
      <meta name="description" content="Summer Camp Hamilton 2024- Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities." />
      <meta property="og:title" content="Bucard | Digital Business Card" />
      <meta property="og:image" content="https://yugalkunj.org/images/Canada_Summer_Camp.jpg" />
      <meta property="og:description" content="Summer Camp in Hamilton 2024!- Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages" />
      <meta property="og:url" content="https://yugalkunj.org/" />
    </Helmet>
    
    <MenuBar />
      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div className="image-layer"></div>

        <div className="auto-container">
          <h1>Summer Camp 2024</h1>
         

        </div>

        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><NavLink to="/CAD/Home">Home</NavLink></li>

              <li className="active">Summer Camp 2024</li>
            </ul>
          </div>
        </div>

      </section>
      {/* <!--End Banner Section --> */}

      <div className="container">



        <div className="auto-container">

          <div className="centered">
            <hr />
            <h3>  Summer Camp 2024</h3>
            <hr />
            <a href="https://ykportal.yugalkunj.org/?ParamCountry=Canada" target="_blank" className="alert alert-primary">
              <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
            </a>
            <hr />
          </div>
        </div>

        <div className="row">

          <div className="col-lg-12">
            <div className="card card-margin">
              <div className="card-header no-border">
                <h5 className="card-title">Summer Camp 2024</h5>

              </div>
              <div className="card-body pt-0">
                <div className="widget-49">


                  <figure className="image">
                    <img src="https://yugalkunj.org/images/Canada_Summer_Camp.jpg" alt="Summer Camp 2024" />
                    <a href="https://yugalkunj.org/images/Canada_Summer_Camp.jpg" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                  </figure>
                  <br />
                  <div className="widget-49-meeting-action">
                    <a href="https://yugalkunj.org/images/Canada_Summer_Camp.jpg" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>



      </div>
      <Footer />
   
   
   </>
  )
}

export default Summer_Camp_Hamilton
