import React from 'react'
import { NavLink } from 'react-router-dom';
import MenuBar from '../Component/MenuBar';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
// import { Carousel } from "react-bootstrap";

function CivicHolidayRetreat() {
    return (
        <>

            <MenuBar />
            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1> Civic Holiday Retreat </h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>

                            <li className="active"> CivicHolidayRetreat </li>
                        </ul>
                    </div>
                </div>

            </section>
            {/* <!--End Banner Section --> */}

            <div className="container">



                <div className="auto-container">

                    <div className="centered">
                        <hr />
                        <h3> Civic Holiday Retreat </h3>
                        <hr />
                        <a href="https://ykportal.yugalkunj.org/?ParamCountry=Canada" target="_blank" className="alert alert-primary">
                            <span className="btn-title"><span className="fa fa-2x fa fa-hand-o-right"></span>  Please click here for registration </span>
                        </a>
                        <hr />
                    </div>
                </div>




                <div className="row">




                    <div className="col-lg-12">
                        <div className="card card-margin">
                            <div className="card-header no-border">
                                <h5 className="card-title">  Civic Holiday Retreat</h5>

                            </div>
                            <div className="card-body pt-0">
                                <div className="widget-49">


                                    <figure className="image">
                                        <img src="https://yugalkunj.org/images/Cvic_HolidayRetreat.jpg" alt="" />
                                        <a href="https://yugalkunj.org/images/Cvic_HolidayRetreat.jpg" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                                    </figure>
                                    <br />
                                    <div className="widget-49-meeting-action">
                                        <a href="https://yugalkunj.org/images/Cvic_HolidayRetreat.jpg" target="_blank" className="btn btn-sm btn-flash-border-primary">View Flyer</a>
                                    </div>



                                    <hr />

                                    {/* <div>



                                    <div className="card-header no-border">
                                <h5 className="card-title">   DEVOTIONAL RETREAT SCHEDULE </h5>

                            </div>
                                         <figure className="image">
                                            <img src="https://yugalkunj.org/images/Vancouver%20retreat%20schedule.jpg" alt="" style={{ width: '100%' }} />
                                            <a href="https://yugalkunj.org/images/Vancouver%20retreat%20schedule.jpg" className="lightbox-image overlay" data-fancybox="gallery-two"></a>
                                        </figure> 

                                    </div> */}



                                </div>
                            </div>
                        </div>
                    </div>




                </div>







                <hr />
            {/* <section className="coming-events">
  
            <div className="auto-container">
              <div className="sec-title centered">
  
                <h2>  Devotional Retreat </h2>
  
  
  
              </div>
  
  
  
              <Carousel variant="dark">
  
                <Carousel.Item >
                  <img
                    className="d-block w-100"
                    src="https://yugalkunj.org/images/Vancouver%20retreat%20schedule.jpg"
                    alt="Second slide"
                  />
  
                </Carousel.Item>
  
  
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://yugalkunj.org/images/Vancouver%20retreat%20schedule.jpg"
                    alt="Third slide"
                  />
  
  
                </Carousel.Item>
  
  
  
              </Carousel>
  
  
  
            </div>
          </section>  */}
















            </div>















































            <Footer />
        </>
    )
}

export default CivicHolidayRetreat;
