import React, { useState } from 'react';
import MenuBar from '../Component/MenuBar'
import { Link, NavLink } from 'react-router-dom';
// import '../../asstes/css/sass/Sidebar.scss';
import '../../asstes/css/sass/classdetails.scss'
import Footer from '../Component/Footer';


const Index = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };
  return (
    <>
      
<MenuBar/>
      <section className="page-banner">
                <div className="image-layer" ></div>

                <div className="auto-container">
                    <h1>Class</h1>
                </div>

                <div className="breadcrumb-box">
                    <div className="auto-container">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/CAD/Home">Home</Link></li>

                            <li className="active">Class</li>
                        </ul>
                    </div>
                </div>

            </section>
    <section id="trending_causes_main" class="section_padding">
        <div class="container">
            <div class="row" id="counter">
                <div class="col-lg-8">
                    <div class="details_wrapper_area">
                        <div class="details_big_img">
                            <img src="https://stssevastorage.blob.core.windows.net/my-container/Harmonium.jfif" alt="img"/>
                        </div>
                        <div class="details_text_wrapper">
                            {/* <a href="events.html" class="tags_noted">Harmonium & Vocal - Intermediate - Saturday - Atlanta</a> */}
                            <h2>Harmonium & Vocal - Intermediate - Saturday - Atlanta</h2>
                            <p>
                            In this class, students who have completed the Beginner Level Harmonium and Vocal Class learn how to sing bhajans and Hindustani classical raags and play them on the harmonium. Many of the keertans and bhajans taught in this class are composed by Jagadguru Shri Kripalu Ji Maharaj. 
                            </p>
                            <p className='text-right'> 
                             <a href="https://ykportal.yugalkunj.org/Home/CountrySelect" target='_' class="btn btn_theme btn_md ">Register now</a>
                             </p>


                            <div className={`panel ${isPanelOpen ? 'panel-primary' : ''}`}>
      <div className='panel panel-heading' onClick={togglePanel} style={{ cursor: 'pointer' }}>
        <h4>
          Class Schedule
          <span className="icon" style={{ float: 'right', marginLeft: '10px' }}>
            {isPanelOpen ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
          </span>
        </h4>
      </div>
      {isPanelOpen && (
        <div className='panel panel-body'>
          <div className="table table-responsive">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Venue</th>
                </tr>
              </thead>
              <tbody>

                    <tr>

                        <td>1/20/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>1/27/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>2/10/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>2/24/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>3/2/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>3/9/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>3/16/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>3/23/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>4/13/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>4/20/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>
                    <tr>

                        <td>4/27/2024</td>
                        <td>



                            9:45AM
                             
                        </td>
                        <td> Peacock Room</td>
                    </tr>



            </tbody>
            </table>
          </div>
        </div>
      )}
    </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sidebar_first">
                        {/* <!-- Project Organizer --> */}
                        <div class="sidebar_boxed">
                            <div class="sidebar_heading_main">
                                <h3>Class Details</h3>
                            </div>
                            <div class="event_details_list">
                                <ul>
                                    <li><i className='fa fa-user'></i> Teacher : <span>Richa Sinha</span>
                                    </li>
                                    <li><i className='fa fa-clock'></i> Duration : <span>1/20/2024 - 5/11/2024</span></li>
                                   
                                    <li><i class="fa fa-hand-holding-usd" aria-hidden="true"></i> Fee: <span>$ 120.00</span>
                                    </li>
                                    <li><i className='fa fa-phone'></i> Phone : <span> 1 (678) 667-6985
                                            789</span>
                                    </li>
                                    <li> <i className='fa fa-envelope-open'></i> Mail :  
                                        <span> Classes.ca@yugalkunj.org</span>
                                    </li>
                                    
                                </ul>
                                <div class="register_now_details">
                                    <a href="https://ykportal.yugalkunj.org/Home/CountrySelect" target='_' class="btn btn_theme btn_md w-100">Register now</a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Recent Donet --> */}
                        {/* <div class="project_recentdonet_wrapper sidebar_boxed">
                            <div class="sidebar_heading_main">
                                <h3>Event organizer</h3>
                            </div>
                            <div class="recent_donet_item">
                                <div class="recent_donet_img">
                                    <a href="cause-details.html">
                                      <img src="assets/img/sidebar/rec-donet-1.png"
                                            alt="img"/></a>
                                </div>
                                <div class="recent_donet_text">
                                    <div class="sidebar_inner_heading">
                                        <h4><a href="cause-details.html">Mike richard</a></h4>
                                    </div>
                                    <p>Managing director</p>
                                    <h6>Care NGO ltd.</h6>
                                </div>
                            </div>
                            <div class="recent_donet_item">
                                <div class="recent_donet_img">
                                    <a href="cause-details.html">
                                      <img src="assets/img/sidebar/rec-donet-2.png"
                                            alt="img"/></a>
                                </div>
                                <div class="recent_donet_text">
                                    <div class="sidebar_inner_heading">
                                        <h4><a href="cause-details.html">Jenifar lawrence</a></h4>
                                    </div>
                                    <p>Entrepreneur</p>
                                    <h6>Own business</h6>
                                </div>
                            </div>
                            <div class="recent_donet_item">
                                <div class="recent_donet_img">
                                    <a href="cause-details.html">
                                      <img src="assets/img/sidebar/rec-donet-3.png"
                                            alt="img"/></a>
                                </div>
                                <div class="recent_donet_text">
                                    <div class="sidebar_inner_heading">
                                        <h4><a href="cause-details.html">David jovan</a></h4>
                                    </div>
                                    <p>Manager</p>
                                    <h6>ABC Company</h6>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Map Causes --> */}
                        <div class="share_causes_wrapper sidebar_boxed">
                             {/* <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                                height="350" style="border:0;width: 100%;"></iframe>  */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5812.131480536848!2d-79.890155!3d43.250044!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9b6c61cff5eb%3A0xec2d7e761feaf66f!2s375%20Aberdeen%20Ave%2C%20Hamilton%2C%20ON%20L8P%202R7%2C%20Canada!5e0!3m2!1sen!2sin!4v1705223762967!5m2!1sen!2sin"
                                 width="100%" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        {/* <!-- share Causes --> */}
                        {/* <div class="share_causes_wrapper sidebar_boxed">
                            <div class="sidebar_heading_main">
                                <h3>Share causes</h3>
                            </div>
                            <div class="social_icon_sidebar">
                                <ul>
                                    <li><a href="#!"><img src="fab fa-facebook-f"  /> Facebook</a></li>
                                    <li><a href="#!"><img src="assets/img/icon/instagram.png"  /></a></li>
                                    <li><a href="#!"><img src="assets/img/icon/twitter.png"  /></a></li>
                                    <li><a href="#!"><img src="assets/img/icon/linkedin.png"  /></a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>

    
    <Footer/>

    </>
  )
}

export default Index
