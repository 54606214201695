import ServiceCards from "./ServiceCards";
import ServiceData from "./ServiceData";
 import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Services = () => {
    //Mobile view  column adjustment
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile view on component mount
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log(ServiceData[0].Desc);
  return (
    <>
      <section className="causes-section1">
        <div className="icon-one1 paroller1" data-paroller-factor="-0.20" data-paroller-factor-lg="-0.20" data-paroller-factor-sm="-0.10" data-paroller-type="foreground" data-paroller-direction="horizontal" style={{ transform: "translateX(29px)", transition: "transform 0s linear 0s", willChange: "transform" }}><span className="flaticon-heart-2"></span></div>
        <div className="auto-container">

          <div className="sec-title centered">

            <h2> Welcome to Yugal Kunj Canada  </h2>

          </div>

        </div>


        <div className="auto-container">

          <div className="sec-title centered">
            <div className="upper-icon1"></div>
            <h2>Yugal Kunj  Services  </h2>

          </div>

          <div className="row row-flex clearfix">

{/* 
              <div className={`cause-block1 col-lg-4 col-md-6 col-sm-12 ${isMobile ? '' : 'offset-2'}`} >
              <div className="inner-box1 wow fadeInUp animated" >

                <div className="image-box1"><figure className="image1">
                  <NavLink to="CAD/OurEvent"
                  >
                    <img src="https://yugalkunj.org/images/satsang.jpg" alt="" />

                  </NavLink>
                </figure></div><div className="lower-content1"><h3><NavLink to='/CAD/OurEvent'>  Weekly Satsangs
                </NavLink>
                </h3><div className="text1">Click here to get more info weekly satsangs
                    <NavLink to='/CAD/OurEvent' className="readmore"> Read More</NavLink>
                  </div><a href="/CAD/OurEvent"  className="theme-btn1 btn-style-two1">
                    <span className="btn-title1"><span className="icon fa fa-sign-in-alt" aria-hidden="true"></span> Upcoming-Event </span>
                  </a>
                </div>
              </div>
            </div> 


             <div className="cause-block1 col-lg-4 col-md-6 col-sm-12">

              <div className="inner-box1 wow fadeInUp animated" >

                <div className="image-box1"><figure className="image1">
                  <a href="https://www.radhamadhavsociety.org/"
                  >
                    <img src="https://stssevastorage.blob.core.windows.net/ykwebsite/resource/inspirational-1.jpg" alt="" /></a>
                </figure></div><div className="lower-content1"><h3><NavLink to="/CAD/TempleActivities">  Temple/Spiritual Activities</NavLink>
                </h3><div className="text1">Daily prayers and aarti, weekly Sunday satsang and celebration of major festivals
                    <a href="https://www.radhamadhavsociety.org/" className="readmore"> Read More</a>
                  </div><a href="https://www.radhamadhavsociety.org/" target="_blank" className="theme-btn1 btn-style-two1">
                    <span className="btn-title1"><span className="icon fa fa-sign-in-alt" aria-hidden="true"></span> Register </span>
                  </a>
                </div>
              </div>
            </div>   */}






            {ServiceData.map((val, index) => {
              return (

                <ServiceCards
                  key={val.id}
                  imgsrc={val.imgsrc}
                  title={val.title}
                  Desc={val.Desc}
                  Link={val.Link}
                  Readbtn={val.Readbtn}
                  registerbtn={val.registerbtn}
                  RegistrationLink={val.RegistrationLink}
                  target={val.target}


                />
              );

            })}  


          </div>

        </div>
      </section>

    </>


  )


}

export default Services;